import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { pickStripe } from "../../../lib/uris";

const stripeKey = window.Stripe(pickStripe());

const withStripeElements = (WrappedComponent) => (props) => (
  <Elements stripe={stripeKey}>
    <WrappedComponent {...props} />
  </Elements>
);

export default withStripeElements;