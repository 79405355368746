import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { useCurrentUser, useTeam } from "../../../lib/hooks";
import { useCreateSbx, useSignupNavigation } from "../shared/hooks";
import { ButtonWrapper } from "../shared/styled";
import { _teamState } from "../../../store";

const _propTypes = {
  details: PropTypes.array.isRequired,
};

export const CTA = ({ details }) => {
  const { id, plan: planDetails, isSubscribed } = useRecoilValue(_teamState);
  const { userLoading } = useCurrentUser();
  const { teamLoading } = useTeam();
  const { createSbx, loading, error } = useCreateSbx();
  const { hasCompletedStep, setCompletedStep, nextRoute } = useSignupNavigation();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading([!details.length, teamLoading, userLoading, loading].some(Boolean));
  }, [details, teamLoading, userLoading, loading]);

  const onConfirm = useCallback(() => {
    if (isError) setError(false);
    if (hasCompletedStep || isSubscribed) {
      setCompletedStep();
      history.push(nextRoute);
    } else {
      createSbx({
        variables: {
          companyId: id,
          planId: planDetails.id,
        },
      }).then(({ data }) => {
        if (data && data.createS4TSbx) {
          setCompletedStep();
          history.push(nextRoute);
        } else {
          setError(true);
        }
      });
    }
  }, [
    createSbx,
    hasCompletedStep,
    history,
    id,
    isError,
    isSubscribed,
    nextRoute,
    planDetails,
    setCompletedStep,
  ]);

  return (
    <ButtonWrapper>
      {(error || isError) && <Error error={error} />}
      <Button onClick={onConfirm} disabled={isLoading} cta>
        {isLoading? <LoadingSpinner loading={isLoading} /> : isSubscribed ? "Continue" : "Confirm & Pay"}
      </Button>
    </ButtonWrapper>
  )
};
CTA.propTypes = _propTypes;
