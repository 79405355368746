import React from "react";
import PropTypes from "prop-types";
import { Error as Container } from "../components/Signup/shared/styled";

const Error = ({ error, style = {} }) => {
  if (error && error.client) {
    return <Container style={style}>{error.client.message}</Container>
  }
  return error && error.graphQLErrors ? (
    error.graphQLErrors.map(({ message }, i) => (
      <Container style={style} key={i}>{message}</Container>
    ))
  ) : (
    <Container style={style}>There was an error. Please try again.</Container>
  );
};

Error.propTypes = {
  error: PropTypes.object,
  style: PropTypes.object,
};

export default Error;
