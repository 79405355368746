import React from "react";
import styled from "styled-components";
import moment from "moment";

//Components
import ListItem from "../components/ListItem";
import MenuHeader from "./MenuHeader";
import MenuControls from "./MenuControls";

const Reports = ({
  company,
  setCurrentReport,
  currentReport,
  setShowReports,
  getTeamReport,
  setShowModal,
}) => {
  return (
    <Container>
      <ReportsContainer>
        <MenuHeader
          onClose={() => {
            setShowReports(false);
            setCurrentReport(null);
          }}
        >
          Reports
        </MenuHeader>
        {company &&
          !!company.reports &&
          company.reports.map((report, index) => (
            <ListItem
              key={index}
              title={moment(report.period_start).format("LL")}
              detail={`to ${moment(report.period_end).format("LL")}`}
              isOpen={currentReport && report.id === currentReport.id}
              onClick={() => {
                setCurrentReport(null);
                getTeamReport({
                  variables: { periodId: report.id },
                });
                setShowModal(false);
              }}
            />
          ))}
      </ReportsContainer>
      <MenuControls />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const ReportsContainer = styled.div`
  overflow: scroll;
  margin: 0 0 20px;
  justify-content: flex-start;
`;

export default Reports;
