import styled from "styled-components";
import variables from '../variables';

const { breakpoints: { small } } = variables;

export const BasicTextProperties = styled.span`
  color: ${({ theme }) => theme.fg};
  display: block;
  font-family: ${variables.favorit.regular};
  letter-spacing: -0.8px;
  line-height: ${({ lineHeight }) => lineHeight || 1.2};
  -webkit-font-smoothing: antialiased;

  a {
    color: ${({ theme }) => theme.fg};
    cursor: pointer;
    text-decoration: ${({ noUnderline }) => noUnderline ? 'none' : 'underline'};
  }

  span {
    color: ${({ theme }) => theme.fg_secondary};
  }
`;

export const Heading = styled(BasicTextProperties)`
  font-size: 32px;

  @media(min-width: ${small}px) {
    font-size: 60px;
  }
`;

export const SectionHeading = styled(BasicTextProperties)`
  font-size: 24px;
`;

export const SubHeading = styled(BasicTextProperties)`
  font-size: 20px;
`;

export const Body = styled(BasicTextProperties)`
  font-size: 18px;
`;

export const FinePrint = styled(BasicTextProperties)`
  font-size: 14px;
`;

