export default {
  titles: {
    welcome: `You're here because you \nalready know coaching works.`,
    getStarted: `Get started with \nthe simple stuff`,
    verify: `Please check \nyour email`,
    teamName: `What’s the name of your \ncompany or team?`,
    corporate: `Let’s get down the \ncorporate stuff`,
    planSetup: name => `Customize ${name ? `${name}'s` : 'your'} plan`,
    billing: `Let’s get your billing set up.`,
    terms: `Sphere for Teams \nSubscription`,
    confirm: `Confirm your \nteam’s growth`,
    invite: `Invite your teammates`,
  },
  subHeadings: {
    welcome: `Self-serve and set your team up with coaching, in 15 mins or less.`,
    getStarted: `Let’s create your admin account, before elevating \nyour team’s EQ.`,
    verify: `Check your email for your 5 character verification \ncode.`,
    billing: `Add your billing information for your team’s plan. \nThis can be changed at anytime.`,
    terms: `Read and sign below`,
    confirm: `Get hyped about everything you’ve set up for \nyour team. Ready to grow?`,
    invite: `Let’s get your team together. Add the emails of all \nteammates you’d like to invite. You can do this later.`,
  },
  finePrint: {
    planSetup: `How much coaching do you want to give each team member, \neach month?`
  }
};
