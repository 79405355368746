import React from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { SubHeading } from "../../components/Typography";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { CTA } from "../../components/Signup/GetStarted/CTA";
import { Form } from "../../components/Signup/GetStarted/Form";

const { titles, subHeadings } = copy;

const GetStarted = ({ ...props }) => {
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.getStarted} hasPrev={hasPrev} prevRoute={prevRoute} inline>
      <SubHeading><span>{subHeadings.getStarted}</span></SubHeading>
      <Form />
      <CTA {...props} />
    </Layout>
  );
};

export default GetStarted;
