const transformFocuses = (focus) => {
  switch (focus) {
    case "Spiritual":
      return "Spirituality";
    case "Intellectual":
      return "Intellect";
    case "Physical":
      return "Physicality";
    case "Financial":
      return "Finance";
    default:
      return focus;
  }
};

const createLines = (words) => {
  let currentIndex = 0;
  const limit = 36;

  return words.reduce(
    (sentences, word) => {
      if ((sentences[currentIndex] + word).length > limit) {
        currentIndex++;
        sentences.push(`${word}`);
      } else {
        sentences[currentIndex] += ` ${word}`;
      }
      return sentences;
    },
    [""]
  );
};

const processedCopy = (copy) => {
  // Create Array of each sentence in the copy.
  let sentences = copy.split(".").map((sentence) => `${sentence}.`);
  sentences.pop();

  // Create array of limited length lines for each sentence.
  sentences = sentences.map((sentence) =>
    createLines(sentence.replace(/@/g, "").split(" "))
  ); //.replace(/@/g, '')

  // Add Indentation to unique sentences.
  sentences.map(
    (sentence) =>
      (sentence[0] = `<span class="textindent">${sentence[0]}</span>`)
  );

  // Flatten sentences so we just have a list of lines
  const lines = sentences.flat();

  return lines;
};

const toSentence = (arr) => arr.join(", ").replace(/,\s([^,]+)$/, " and $1");

const insights = (teamName, report, slide) => {
  const copy = {
    focus: `The ${teamName} Team have found growth with ${toSentence(
      report.insights.communication_style
    )} communication styles & focused mostly on ${toSentence(
      report.insights.focus.map((focus) => transformFocuses(focus))
    )}. These unique preferences have been facilitated by ${
      report.insights.numGuides
    } ${
      report.insights.numGuides === 1 ? "Guide" : "Guides"
    } across the platform.`,
    response: `Your team believes that ${toSentence(
      report.insights.happiness_factor
    )} would make them 5% happier. ${
      report.insights.feelings.length &&
      `After sessions the most common feelings are ${toSentence(
        report.insights.feelings
      )}.`
    }`,
    utilization: `${
      report.insights.utilization
    }% of the ${teamName} team are currently growing with the support of coaching sessions${
      report.insights.nps
        ? `, and rated the experience ${Math.round(report.insights.nps)}/10.`
        : `.`
    }`,
  };

  return processedCopy(copy[slide]);
};

export default insights;
