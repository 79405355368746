import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { ButtonWrapper } from "../shared/styled";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { useSignupNavigation, useCreateCompany, useEditCompany } from "../shared/hooks";
import { useTeam } from "../../../lib/hooks";
import { _teamState } from "../../../store";

const { teamName: { attrs } } = inputFieldOptions;

export const CTA = () => {
  const history = useHistory(); 
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { id, name } = useRecoilValue(_teamState);
  const { teamData, teamLoading } = useTeam();
  const { createCompany, loading, error } = useCreateCompany();
  const { editCompany, loading: editLoading, error: editError } = useEditCompany();
  const { hasCompletedStep, nextRoute, setCompletedStep } = useSignupNavigation();

  useEffect(() => {
    setDisabled([name.length < attrs.minLength, !name.trim().length, isLoading].some(Boolean));
  }, [name, isLoading]);

  useEffect(() => {
    setLoading([loading, editLoading, teamLoading].some(Boolean));
  }, [loading, editLoading, teamLoading, setLoading]);

  useEffect(() => {
    if (teamData && teamData.getTeam) {
      const { getTeam: { name } } = teamData;
      if (name) setCompletedStep();
    }
  }, [setCompletedStep, teamData]);

  const onCreate = useCallback(() => {
    createCompany({ 
      variables: {
        name: name.trim()
      }
    })
    .then(({ data }) => {
      if (data && data.createCompany) {
        setCompletedStep();
        history.push(nextRoute);
      }
    })
  }, 
    [
      createCompany, 
      history, 
      name, 
      nextRoute, 
      setCompletedStep,
    ]
  );

  const onEdit = useCallback(() => {
    if (teamData) {
      const { getTeam: { name: dbName } } = teamData;
      if (name === dbName) {
        history.push(nextRoute);
      } else {
        editCompany({
          variables: {
            companyId: id,
            name: name.trim()
          }
        })
        .then(({ data }) => {
          if (data) {
            setCompletedStep();
            history.push(nextRoute);
          }
        })
      }
    }
  }, 
    [ 
      editCompany,
      history, 
      id,
      name, 
      nextRoute, 
      setCompletedStep,
      teamData,
    ]
  );

  return (
    <ButtonWrapper>
      {editError && <Error error={editError} />}
      {error && <Error error={error} />}
      <Button onClick={hasCompletedStep ? onEdit : onCreate} disabled={disabled} cta>
        {isLoading ? <LoadingSpinner loading={isLoading} /> : "Next"}
      </Button>
    </ButtonWrapper>
  )
};
