import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSignupNavigation, useAgreeToTerms } from "../shared/hooks";
import PropTypes from "prop-types";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { ButtonWrapper } from "../shared/styled";

const _propTypes = {
  acceptedTerms: PropTypes.bool.isRequired,
  companyId: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameToValidate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const CTA = ({ acceptedTerms, companyId, name, nameToValidate, title }) => {
  const [disabled, setDisabled] = useState(true);
  const { hasCompletedStep, nextRoute, setCompletedStep } = useSignupNavigation();
  const { agreeToTerms, loading, error } = useAgreeToTerms();
  const history = useHistory();

  useEffect(() => {
    setDisabled(
      hasCompletedStep || acceptedTerms
      ? false 
      : [
      name.toLowerCase().trim() !== nameToValidate,
      !title.length
    ].some(Boolean));
  }, [acceptedTerms, hasCompletedStep, name, nameToValidate, title]);

  const onClick = useCallback(() => {
    if (hasCompletedStep || acceptedTerms) {
      setCompletedStep();
      history.push(nextRoute);
    } else {
      agreeToTerms({
        variables: {
          companyId,
          title: title.trim()
        }
      })
      .then(({ data }) => {
        const { storeS4Ttc } = data;
        if (storeS4Ttc) {
          // TODO: p-api saves t_and_c successfully but returns {storeS4Ttc: false}. Once the BE bug is fixed, move functions back inside IF block
        }
        setCompletedStep();
        history.push(nextRoute);
      });
    }
  }, 
    [
      acceptedTerms,
      agreeToTerms, 
      companyId,
      hasCompletedStep, 
      history, 
      nextRoute, 
      setCompletedStep,
      title
    ]
  );

  return (
    <ButtonWrapper>
      {error && <Error error={error} />}
      <Button cta disabled={disabled || loading} onClick={onClick}>
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          acceptedTerms ? "Continue" : "Sign Agreement"
        )}
      </Button>
    </ButtonWrapper>
  )
};

CTA.propTypes = _propTypes;
