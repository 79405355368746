import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useRecoilValue } from "recoil";
import { CardElement } from "@stripe/react-stripe-js";
import variables from "../../../variables";
import { SubHeading } from "../../Typography";
import { Container, FormGroup } from "../shared/styled";
import { _userState } from "../../../store";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: variables.white,
      color: variables.white,
      fontFamily: "Favorit-Regular",
      fontSize: "18px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      color: variables.error,
    },
  },
};

export const Form = () => {
  const { paymentMethods } = useRecoilValue(_userState);
  
  return (
    <Container>
      <FormGroup>
        <div style={{ margin: "20px 0" }}>
          {paymentMethods && paymentMethods.length ? (
            <TransitionGroup component={null}>
              <CSSTransition classNames="fade" timeout={300}>
                <SubHeading>{`${
                  paymentMethods[0].brand[0].toUpperCase() +
                  paymentMethods[0].brand.substring(1)
                } ending in ${paymentMethods[0].last4} added.`}</SubHeading>
              </CSSTransition>
            </TransitionGroup>
          ) : (
            <TransitionGroup>
              <CSSTransition classNames="fade" timeout={300}>
                <CardElement options={CARD_OPTIONS} />
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      </FormGroup>
    </Container>
  )
};
