import React from "react";
import { useRecoilValue } from "recoil";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "../../components/Signup/shared/Layout";
import TeamSizeSlider, { MAX_RANGE } from "../../components/Signup/PlanSetup/TeamSizeSlider";
import copy from "../../components/Signup/shared/communications";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { _teamState } from "../../store";
import { CTA, CTAAlternate } from "../../components/Signup/PlanSetup/CTA";
import { Form, FormAlternate } from "../../components/Signup/PlanSetup/Form";

const { titles } = copy;

const PlanSetup = () => {
  const { name, size } = useRecoilValue(_teamState);
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.planSetup(name)} hasPrev={hasPrev} prevRoute={prevRoute}>
      <TeamSizeSlider />
      {size !== MAX_RANGE ? (
        <TransitionGroup component={null}>
          <CSSTransition classNames="fade" timeout={300}>
            <>
              <Form />
              <CTA />
            </>
          </CSSTransition>
        </TransitionGroup>
      ) : (
        <TransitionGroup>
          <CSSTransition classNames="fade" timeout={300}>
            <>
              <FormAlternate />
              <CTAAlternate />
            </>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Layout>
  );
};

export default PlanSetup;
