import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

const withRouter = (WrappedComponent) => ({ ...props }) => (
  // can't use hooks from within the same component that puts Router into the tree
  <Router>
    <WrappedComponent {...props} />
  </Router>
);

export default withRouter;