import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useBounceToRoute } from "../components/Signup/shared/hooks";

const _propTypes = { isSignup: PropTypes.bool.isRequired };

const InvalidRoute = ({ isSignup, isAuthenticated }) => {
  const pathname = useBounceToRoute(isSignup, isAuthenticated);

  if (!pathname) return null;

  return (
    <Redirect
      to={pathname}
    />
  )
};

InvalidRoute.propTypes = _propTypes;

export default InvalidRoute;
