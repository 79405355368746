import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { SubHeading } from "../../components/Typography";
import { CTA } from "../../components/Signup/Verify/CTA";
import { Form } from "../../components/Signup/Verify/Form";
import { useResendVerification, useSignupNavigation } from "../../components/Signup/shared/hooks";
import { _userState } from "../../store";

const { titles, subHeadings } = copy;

const Verify = () => {
  const [codeResent, setCodeResent] = useState(false);
  const { resend, data: resendData } = useResendVerification();
  const { email } = useRecoilValue(_userState);
  const { hasPrev, prevRoute } = useSignupNavigation();

  useEffect(() => {
    if (resendData) {
      setCodeResent(true);
      setTimeout(() => {
        setCodeResent(false);
      }, 2000);
    }
  }, [resendData]);

  const onResend = useCallback(() => {
    resend({
      variables: {
        email,
      },
    });
  }, [resend, email]);

  return (
    <Layout title={titles.verify} hasPrev={hasPrev} prevRoute={prevRoute}>
      <SubHeading>
        <span>
          {subHeadings.verify}{" "}
          {!codeResent ? (
            <>
              {/* eslint-disable-next-line */}
              <a title="resend verification code" onClick={onResend}>
                Click here
              </a>{" "}
              to resend the code.
            </>
          ) : (
            `Code resent.`
          )}
        </span>
      </SubHeading>
      <Form />
      <CTA />
    </Layout>
  );
};

export default Verify;
