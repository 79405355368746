import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import variables from "../../../variables";
import { Login, GetStarted } from "./fragments";
import { useInterval } from "../../../lib/hooks";
import { useSignupNavigation } from "../shared/hooks";

const _propTypes = {
  disabled: PropTypes.bool.isRequired,
  hasCompletedStep: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setSignup: PropTypes.func.isRequired,
};

const { breakpoints: { small } } = variables;

const componentMap = [{ Component: GetStarted }, { Component: Login }].map(
  (item, index) => {
    item.id = index;
    return item;
  }
);

export const CTA = ({ disabled, hasCompletedStep, setSignup, isVisible }) => {
  const [visibleItems, setVisibleItems] = useState(new Array(componentMap.length).fill(false));
  const { nextRoute, setCompletedStep } = useSignupNavigation();
  const history = useHistory();

  useInterval(
    () => {
      if (isVisible) {
        const itemIndex = visibleItems.findIndex((item) => !item);
        const items = visibleItems;
        items[itemIndex] = true;
        setVisibleItems([...items]);
      }
    },
    350,
    visibleItems.every(Boolean)
  );

  const onClickGetStarted = useCallback(() => {
    setCompletedStep();
    history.push(nextRoute);
  }, [history, nextRoute, setCompletedStep]);

  const onClickLogin = useCallback(() => {
    if (localStorage.getItem("plan")) {
      localStorage.removeItem("plan");
    }
    setSignup(false);
    window.location.reload();
  }, [setSignup]);

  return (
    <Container>
      {componentMap.map(({ id, Component }) => {
        const isItemVisible = visibleItems[id] || hasCompletedStep;
        let onClick;
        let isDisabled;

        if (Component.displayName === GetStarted.displayName) {
          onClick = onClickGetStarted;
          isDisabled = disabled;
        }
        if (Component.displayName === Login.displayName) {
          onClick = onClickLogin;
        }
        return (
          <Component
            key={id}
            disabled={isDisabled}
            isVisible={isItemVisible}
            onClick={onClick}
          />
        );
      })}
    </Container>
  );
};

CTA.propTypes = _propTypes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${small}px) {
    flex-direction: row;
  }
`;
