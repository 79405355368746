import React from "react";
import styled from "styled-components";

// Components
import SphereTeamsLogo from "./SphereTeamsLogo";

// Assets
import MenuIcon from "../images/menu.png";

const Navigation = ({ setToken, setShowModal, showModal }) => (
  <Container>
    <SphereTeamsLogo />
    <Menu onClick={() => setShowModal(!showModal)}>
      <img src={MenuIcon} alt="Menu Icon" />
    </Menu>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  border: none;
  background: none;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div`
  width: 30px;
  height: 31px;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
  }
`;

export default Navigation;
