import React, { useCallback, useState } from 'react';
import styled, { css } from "styled-components";
import PropTypes from 'prop-types';
import variables from "../../../variables";

const chevron = require("../../../images/chevron.png");

const _propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const Dropdown = ({ options, type, value, onClick, style }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const onToggleDropdown = useCallback(() => {
    if (options) setDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen, setDropdownOpen, options]);

  const onClickItem = useCallback((item) => {
    setDropdownOpen(false);
    onClick(item);
  }, [onClick]);  

  return (
    <Container styles={css`${style}`}>
      <Display onClick={onToggleDropdown}>
        <Input placeholder={type} value={value} disabled={true} />
        <Arrow isDropdownOpen={isDropdownOpen} />
      </Display>
      <DropdownContainer isDropdownOpen={isDropdownOpen} >
        {options && options.filter(option => Object.keys(option).length).map(item => (
          <DropdownItem key={item.id} onClick={() => onClickItem(item)} selected={item.label === value}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownContainer>
    </Container>
  );
};

Dropdown.propTypes = _propTypes;

export default Dropdown;

const Arrow = styled.div`  
  background: center / contain no-repeat url(${chevron});
  height: 20px;
  transform: rotate(${({ isDropdownOpen }) => isDropdownOpen ? `270deg` : `90deg`});
  transition: transform 50ms ease-out;
  width: 20px;
`;

const Container = styled.div`
  position: relative;
  ${({ styles }) => styles};
`;

const Display = styled.div`
  cursor: pointer;
  display: flex;
  flex-directin: row;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid ${variables.white};
  padding-bottom: 5px;
  width: 100%;  
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isDropdownOpen }) => isDropdownOpen ? "200px" : 0};
  overflow: ${({ isDropdownOpen }) => isDropdownOpen ? "scroll" : "hidden"};
  position: absolute;
  top: 27px;
  right: 0;
  left: 0;
  transition: height 150ms ease-in-out;
  z-index: 3;
`;

const DropdownItem = styled.a`
  background: ${({ theme, selected }) => selected ? theme.fg_secondary : theme.bg_secondary};
  border-bottom: 1px solid ${({ theme }) => theme.bg};
  color: ${({ theme, selected }) => selected ? theme.bg_secondary : theme.fg};
  padding: 10px;
  transition: all 150ms ease-out;

  &:hover {
    background: ${({ theme }) => theme.fg};
    color: ${({ theme }) => theme.bg};
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: ${variables.white};
  font-size: 18px;
  outline: none;
  width: inherit;
  &:focus {
    outline: none;
  }
`;