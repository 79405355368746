import React, { useState, useEffect } from "react";
import { useQuery, useManualQuery } from "graphql-hooks";
import styled from "styled-components";
import moment from "moment";

// Components
import Navigation from "../../components/Navigation";
import InsightDetail from "../../components/InsightDetail";
import TextReveal from "../../components/TextReveal";
import Modal from "../../components/Modal";

// Lib
import insights from "../../lib/insights";
import variables from "../../variables";

// Assets
import ArrowDownImage from "../../images/arrow_down.png";
import { TEAM_QUERY, GET_REPORT_QUERY } from "../../network/queries";

const {
  breakpoints: { small },
} = variables;

function Insights({ setToken }) {
  const { data, error, refetch } = useQuery(TEAM_QUERY);
  const [getTeamReport, reportQueryResult] = useManualQuery(GET_REPORT_QUERY);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentReport, setCurrentReport] = useState(null);
  const [isChangingSlide, setIsChangingSlide] = useState(false);
  const [transitionTimeoutId, setTransitionTimeoutId] = useState(null);
  const [changingSlideTimeoutId, setChangingSlideTimeoutId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const hasReport = currentReport && currentReport.insights;
  const slides = ["focus", "response", "utilization"];

  // On Render Animation
  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  // Handle Logout
  useEffect(() => {
    if (error && error.httpError && error.httpError.status === 500)
      setToken(null);
  }, [error, setToken]);

  // Update Page On Receiving Data
  useEffect(() => {
    if (data && data.getTeam) {
      setCurrentReport(data.getTeam.last_report);
      document.title = `${data.getTeam.name}`

    }
  }, [data]);

  // Change Current Report When Query Completes
  useEffect(() => {
    if (reportQueryResult && reportQueryResult.data) {
      const newReport = reportQueryResult.data.getTeamReport;
      setCurrentReport(newReport);
    }
  }, [reportQueryResult]);

  // Handle Slide Transition Callbacks
  useEffect(() => {
    const nextSlide = (event) => {
      if (!isChangingSlide && !showModal) {
        setIsChangingSlide(true);

        // Clear Previous Timeouts
        clearTimeout(transitionTimeoutId);
        clearTimeout(changingSlideTimeoutId);

        // Create New Timeouts
        let timeoutId = setTimeout(() => {
          setCurrentSlide((currentSlide) => {
            if (event.deltaY < 0.01) {
              return currentSlide === 0 ? 2 : currentSlide - 1;
            } else {
              if (currentSlide === 2) return 0;
              return currentSlide === 2 ? 0 : currentSlide + 1;
            }
          });
        }, 1200);
        let slideBlockerTimeoutId = setTimeout(() => {
          setIsChangingSlide(false);
        }, 1200);

        // Set Timeouts for clearing them
        setTransitionTimeoutId(timeoutId);
        setChangingSlideTimeoutId(slideBlockerTimeoutId);
      }
    };

    document.addEventListener("wheel", nextSlide);
    document.addEventListener("touchstart", nextSlide);
    return () => {
      document.removeEventListener("wheel", nextSlide);
      document.removeEventListener("touchstart", nextSlide);
    };
  }, [
    isChangingSlide,
    currentSlide,
    transitionTimeoutId,
    showModal,
    changingSlideTimeoutId,
  ]);

  return (
    <Container isRendered={isRendered}>
      <Modal
        company={data ? data.getTeam : {}}
        showModal={showModal}
        setToken={setToken}
        setShowModal={setShowModal}
        refetch={refetch}
        currentReport={currentReport}
        setCurrentReport={setCurrentReport}
        getTeamReport={getTeamReport}
      />
      <Navigation
        setToken={setToken}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <Grid>
        <InsightDetails>
          <InsightDetail title="Welcome" value={data && data.getTeam.name} />
          {hasReport && (
            <InsightDetail title="Current Page" value={slides[currentSlide]} />
          )}
          <InsightDetail
            title="Current Report"
            value={
              hasReport
                ? moment(currentReport.period_start).format("LL")
                : "No Reports Yet"
            }
          />
        </InsightDetails>

        {currentReport && data.getTeam && (
          <Insight>
            {hasReport ? (
              insights(
                data.getTeam.name,
                currentReport,
                slides[currentSlide]
              ).map((line, index) => (
                <TextReveal
                  key={index}
                  delay={100 * index + 50}
                  html={`${line}`}
                  shouldHide={isChangingSlide}
                  insight
                />
              ))
            ) : (
              <EmptyState>
                Welcome to your Team's Insights Portal. This is where you can
                track and edit your plan, as well as observe and manage your
                team's redemption of credits.
                <br />
                <br />
                This is also the place you'll land when you want to check out
                your team's growth story, which will help you understand what's
                moving and motivating your people. Check back here{" "}
                {data.getTeam && data.getTeam.nextDeposit
                  ? `on ${data.getTeam.nextDeposit}`
                  : `in one month`}{" "}
                to see your team's first report.
              </EmptyState>
            )}
          </Insight>
        )}

        {hasReport && (
          <Instructions>
            <div>
              <ArrowDown src={ArrowDownImage} alt="Down Arrow" />
              swipe for more
            </div>
            <Counter>
              {currentSlide + 1}/{slides.length}
            </Counter>
          </Instructions>
        )}
      </Grid>
    </Container>
  );
}

const breakpoint = 800; // TODO look at variables.breakpoints and figure out why this is different

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  opacity: ${({ isRendered }) => (isRendered ? 1 : 0)};
  transition: opacity 2.4s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Grid = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 50px auto 20px;
  grid-template-columns: 15vw auto;
  grid-row-gap: 20px;
  grid-template-areas:
    "padding details"
    "padding insight"
    "padding instructions";
  margin-top: 20px;

  @media (max-width: ${breakpoint}px) {
    grid-template-rows: min-content auto 20px;
    grid-template-columns: 0 auto;
  }
`;

const InsightDetails = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: details;
  justify-content: space-between;
  padding: 0 80px;

  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
    padding: 0;
    > div {
      margin: 10px 0 10px 20px;
    }
  }
`;

const Insight = styled.div`
  color: ${({ theme }) => theme.fg};
  display: flex;
  flex-direction: column;
  font-size: 4vw;
  grid-area: insight;
  justify-content: center;
  letter-spacing: -0.5px;
  line-height: 4.4vw;
  padding-bottom: 2.5%;

  .textindent {
    text-indent: 80px;
  }

  @media (max-width: ${breakpoint}px) {
    font-size: 5.2vw;
    line-height: 5.6vw;
    .textindent {
      text-indent: 20px;
    }
  }
`;

const Instructions = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.fg};
  display: flex;
  flex-direction: row;
  font-size: 20px;
  grid-area: instructions;
  justify-content: space-between;
  padding: 0 80px;

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${breakpoint}px) {
    font-size: 16px;
    padding: 0;
  }
`;

const Counter = styled.div`
  opacity: 0.4;
  transition: opacity 0.4s ease-in;
  &:hover {
    opacity: 1;
  }
`;

const ArrowDown = styled.img`
  height: 20px;
  margin-right: 5px;
  width: 20px;

  @media (max-width: ${breakpoint}px) {
    height: 16px;
    width: 16px;
  }
`;

const EmptyState = styled.div`
  color: ${({ theme }) => theme.fg};
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 40px;
  text-align: left;
  width: 50%;

  @media (max-width: ${small}px) {
    width: 340px;
  }
`;

export default Insights;
