import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { useTeam } from "../../../lib/hooks";
import { useSignupNavigation, useUpdateField, useNewsletterSubscribe } from "../shared/hooks";
import { _teamState, _userState } from "../../../store";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { ButtonWrapper } from "../shared/styled";

const _propTypes = {
  unavailable: PropTypes.bool.isRequired,
  setUnavailable: PropTypes.func.isRequired,
};

export const CTA = ({ unavailable, setUnavailable }) => {
  const history = useHistory();
  const teamState = useRecoilValue(_teamState);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const { firstName, email } = useRecoilValue(_userState);
  const { teamLoading } = useTeam();
  const { newsletterSubscribe, loading: newsletterLoading } = useNewsletterSubscribe();
  const { hasCompletedStep, setCompletedStep, nextRoute } = useSignupNavigation();
  const {
    id,
    city,
    corporateName,
    corporateAddress1,
    corporateAddress2,
    country,
    postalCode,
    province,
  } = teamState;
  const { updateField, loading, error } = useUpdateField(id);

  useEffect(() => {
    setLoading([loading, newsletterLoading, teamLoading].some(Boolean));
  }, [loading, newsletterLoading, teamLoading]);

  useEffect(() => {
    const disabled =
      country.value === "OTHER" || hasCompletedStep
        ? false
        : inputFieldOptions.corporate
            .map(({ attrs, value }) => {
              if (value === "country" || value === "province") {
                return ![country.value, province.value].every(Boolean);
              }
              if (value === "postalCode") {
                return teamState[value].length < attrs.minLength(country.value);
              }
              return teamState[value].length < attrs.minLength;
            })
            .some(Boolean);

    setDisabled(disabled);
  }, [country, hasCompletedStep, province, setDisabled, teamState]);

  const onNewsletterSubscribe = useCallback(() => {
    newsletterSubscribe({
      variables: {
        name: firstName.trim(),
        email: email.trim(),
      },
    }).then(({ data }) => {
      const { newsletterSubscribe } = data;
      setSubscribed(newsletterSubscribe);
      if (newsletterSubscribe) {
        setTimeout(() => {
          window.open("https://sphere.guide", "_self");
        }, 2000);
      }
    });
  }, [newsletterSubscribe, firstName, email, setSubscribed]);

  const onClick = useCallback(() => {
    if (country.value === "OTHER") {
      setUnavailable(true);
    } else {
      const mailingAddress = {
        dba: corporateName.trim(),
        address_1: corporateAddress1.trim(),
        address_2: corporateAddress2.trim(),
        city: city.trim(),
        country: country.value.trim(),
        stprv: province.label.trim(),
        postal_code: postalCode.trim(),
      };

      updateField("mailing_address", mailingAddress).then(({ data }) => {
        const { updateProfileField } = data;
        if (updateProfileField) {
          history.push(nextRoute);
          setCompletedStep();
        }
      });
    }
  }, [
    city,
    corporateAddress1,
    corporateAddress2,
    corporateName,
    country,
    history,
    nextRoute,
    postalCode,
    province,
    setCompletedStep,
    setUnavailable,
    updateField,
  ]);

  return (
    <ButtonWrapper>
      {error && <Error error={error} />}
      <Button
        onClick={unavailable ? onNewsletterSubscribe : onClick}
        disabled={disabled || isLoading}
        cta
      >
        {isLoading ? (
          <LoadingSpinner loading={isLoading} />
        ) : unavailable ? (
          subscribed ? (
            "Subscribed"
          ) : (
            "Stay Informed"
          )
        ) : (
          "Next"
        )}
      </Button>
    </ButtonWrapper>
  );
};
CTA.propTypes = _propTypes;
