import React from "react";
import styled from "styled-components";

// Assets
import ChevronImage from "../images/chevron.png";

const ListItem = ({ title, detail, onClick, isOpen }) => {
  return (
    <Container onClick={onClick}>
      <Info>
        <Title>{title}</Title>
        <Detail>{detail}</Detail>
      </Info>
      <Chevron src={ChevronImage} isOpen={isOpen} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 0 0 20px;
  align-items: center;
  cursor: pointer;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.div`
  color: white;
  font-size: 20px;
`;

const Detail = styled.form`
  font-size: 16px;
  color: #fff7b6;
  @media (max-width: 768px) {
    font-size: 14px;
    word-break:break-word;
  }
`;

const Chevron = styled.img`
  width: 24px;
  height: 24px;
  transition: opacity 0.3s ease-in;
  opacity: ${({ isOpen }) => (isOpen ? 0.2 : 1)};
`;

export default ListItem;
