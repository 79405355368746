import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { Body, SectionHeading } from "../../Typography";
import { Container, FormGroup } from "../shared/styled";
import { plan } from "../../../store";
import { 
  Length, 
  Commitment, 
  // Billing, 
  Payment 
} from "./fragments";

export const Form = () => {
  const setPlan = useSetRecoilState(plan);

  const onChange = useCallback(
    (item, type) => {
      setPlan((plan) => ({
        ...plan,
        [type]: item,
      }));
    },
    [setPlan]
  );

  return (
    <Container>
      <FormGroup>
        <Length onChange={onChange} />
        <Commitment onChange={onChange} />
        {/* <Billing onChange={onChange} /> */}
        <Payment />
      </FormGroup>
    </Container>
  )
};

export const FormAlternate = () => (
  <Container>
    <FormGroup>
      <SectionHeading>That's a solid team.</SectionHeading>
      <div style={{ marginTop: 10 }} />
      <Body>
        Book a discovery call to make sure we get everyone taken
        care of.
      </Body>
      <div style={{ marginTop: 30 }} />
    </FormGroup>
  </Container>
);
