import React from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { SubHeading } from "../../components/Typography";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import List from "../../components/Signup/Welcome/List";

const { titles, subHeadings } = copy;

const Welcome = ({ ...props }) => {
  const { hasPrev, prevRoute, hasCompletedStep } = useSignupNavigation();

  return (
    <Layout title={titles.welcome} hasPrev={hasPrev} prevRoute={prevRoute}>
      <SubHeading><span>{subHeadings.welcome}</span></SubHeading>
      <List hasCompletedStep={hasCompletedStep} {...props} />
    </Layout>
  );
};

export default Welcome;
