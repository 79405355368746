export const inputFieldOptions = {
  getStarted: [
    {
      id: 0,
      value: "firstName",
      style: {
        gridColumn: 'span 1 / auto'
      },
      attrs: {
        autoFocus: true,
        minLength: 1,
        name: "First Name",
        placeholder: "First Name",
      }
    },
    {
      id: 1,
      value: "lastName",
      style: {
        gridColumn: 'span 1 / auto'
      },
      attrs: {
        minLength: 1,
        name: "Last Name",
        placeholder: "Last Name",
      }
    },
    {
      id: 2,
      value: "email",
      style: {
        gridColumn: 'span 2 / auto'
      },
      attrs: {
        minLength: 6,
        name: "Email",
        placeholder: "Email",
      }
    },
    {
      id: 3,
      value: "password",
      style: {
        gridColumn: 'span 2 / auto'
      },
      attrs: {
        minLength: 8,
        name: "Password",
        placeholder: "Password",
        type: "password",
      }
    },
  ],
  verify: {
    id: 0,
    attrs: {
      autoFocus: true,
      minLength: 5,
      name: "Verify",
      placeholder: "Your Verification Code",
    },
  },
  teamName: {
    id: 0,
    attrs: {
      autoFocus: true,
      minLength: 3,
      name: "You Company Name",
      placeholder: "You Company Name",
    },
  },
  corporate: [
    {
      id: 0,
      value: "corporateName",
      attrs: {
        autoFocus: true,
        minLength: 3,
        name: "Corporate Name",
        placeholder: "Corporate Name",
      }
    },
    {
      id: 1,
      value: "corporateAddress1",
      attrs: {
        minLength: 3,
        name: "Corporate Address",
        placeholder: "Corporate Address",
      }
    },
    {
      id: 2,
      value: "corporateAddress2",
      attrs: {
        minLength: 0,
        name: "Corporate Address (optional)",
        placeholder: "Corporate Address (optional)",
      }
    },
    {
      id: 3, 
      value: "city",
      attrs: {
        minLength: 3,
        name: "City",
        placeholder: "City",
      }
    },
    {
      id: 4,
      dropdown: true,
      value: "country",
      attrs: {
        minLength: 2,
        name: "Country",
        placeholder: "Country",
      }
    },
    {
      id: 5,
      value: "postalCode",
      attrs: {
        minLength: (country) => (country === "US" ? 5 : 6),
        name: "Postal / Zip code",
        placeholder: (country) => (country === "US" ? "Zip Code" : "Postal Code"),
      }
    },
    {
      id: 6,
      dropdown: true,
      value: "province",
      attrs: {
        minLength: 2,
        name: "State / Province",
        placeholder: (country) => (country === "US" ? "State" : "Province"),
      }
    },
  ],
  emailField: {
    id: 0,
    attrs: {
      minLength: 6,
      name: "Email",
      placeholder: (name) => `name@${name || "example"}.com`,
    }
  },
};
