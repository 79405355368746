import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import Account from "./Account";
import Team from "./Team";
import Member from "./Member";
import Reports from "./Reports";
import BillingStripeWrapper from "./Billing";

function Modal({
  company,
  setToken,
  showModal,
  setShowModal,
  refetch,
  currentReport,
  setCurrentReport,
  getTeamReport,
}) {
  const [currentMember, setCurrentMember] = useState(null);
  const [showTeam, setShowTeam] = useState(window.innerWidth > 600);
  const [showBilling, setShowBilling] = useState(false);
  const [showReports, setShowReports] = useState(false);

  useEffect(() => {
    if (showBilling) {
      setCurrentMember(null);
      setShowTeam(false);
      setShowReports(false);
    }
  }, [showBilling, setCurrentMember, setShowTeam, setShowReports]);

  useEffect(() => {
    if (showReports) {
      setCurrentMember(null);
      setShowTeam(false);
      setShowBilling(false);
    }
  }, [showReports, setCurrentMember, setShowTeam, setShowReports]);

  useEffect(() => {
    if (showTeam) {
      setShowBilling(false);
      setShowReports(false);
    }
  }, [showTeam, setShowBilling, setShowReports]);

  return (
    <Container showModal={showModal} onClick={() => setShowModal(false)}>
      <MenuPane
        onClick={(evt) => evt.stopPropagation()}
        showModal={showModal}
        position={0}
      >
        <Account
          company={company}
          setToken={setToken}
          setShowModal={setShowModal}
          showBilling={showBilling}
          setShowBilling={setShowBilling}
          setCurrentMember={setCurrentMember}
          showTeam={showTeam}
          setShowTeam={setShowTeam}
          showReports={showReports}
          setShowReports={setShowReports}
        />
      </MenuPane>
      <MenuPane
        showModal={showModal && showReports} // !(isTablet(windowWidth) && currentMember) &&
        position={1}
        onClick={(evt) => evt.stopPropagation()}
      >
        <Reports
          company={company}
          setCurrentReport={setCurrentReport}
          currentReport={currentReport}
          getTeamReport={getTeamReport}
          setShowReports={setShowReports}
          setShowModal={setShowModal}
        />
      </MenuPane>
      <MenuPane
        showModal={showModal && showTeam} // !(isTablet(windowWidth) && currentMember) &&
        position={1}
        onClick={(evt) => evt.stopPropagation()}
      >
        <Team
          company={company}
          setCurrentMember={setCurrentMember}
          currentMember={currentMember}
          refetch={refetch}
          setShowTeam={setShowTeam}
        />
      </MenuPane>
      <MenuPane
        showModal={showModal && currentMember && !showBilling}
        position={2}
        onClick={(evt) => evt.stopPropagation()}
      >
        {currentMember && (
          <Member
            member={currentMember}
            setCurrentMember={setCurrentMember}
            refetch={refetch}
            currentReport={currentReport}
          />
        )}
      </MenuPane>
      <MenuPane
        showModal={showModal && showBilling}
        position={1}
        onClick={(evt) => evt.stopPropagation()}
      >
        <BillingStripeWrapper
          company={company}
          refetch={refetch}
          setShowBilling={setShowBilling}
        />
      </MenuPane>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: background 1s cubic-bezier(0.24, 1, 0.32, 1);
  background: rgba(12, 12, 12, ${({ showModal }) => (showModal ? 0.5 : 0)});
  pointer-events: ${({ showModal }) => (showModal ? "auto" : "none")};
`;

const MenuPane = styled.div`
  display: flex;
  height: 100%;
  min-width: 375px;
  padding: 15px;
  position: absolute;
  top: 0;
  z-index: ${(props) => props.position * -2 + 10};
  background: ${({ position }) =>
    `rgba(${position * 8 + 0},${position * 8 + 0},${position * 8 + 0}, 1)`};
  transition: all ${({ position }) => position * 0.4 + 0.8}s
    cubic-bezier(0.24, 1, 0.32, 1);
  left: ${({ showModal, position }) => (showModal ? position * 375 : -500)}px;

  @media (min-width: 1128px) {
    width: 33.33vw;

    left: ${({ showModal, position }) =>
      showModal ? position * 33.33 : -33.33}vw;
  }

  @media (max-width: 1127px) {
    width: 50vw;

    z-index: ${({ position }) => (position ? position * 2 + 10 : 100)};
    left: ${({ showModal, position }) =>
      showModal ? (position ? 50 : 0) : -50}vw;
  }

  @media (max-width: 768px) {
    width: 100vw;
    left: ${({ showModal }) => (showModal ? 0 : -150)}vw;
    z-index: ${(props) => props.position * 2 + 10};
  }
`;

export default Modal;
