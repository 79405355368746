export const ADD_PM_MUTATION = `
  mutation addPaymentMethod($userId: ID!, $token: String!) {
    addPaymentMethod(userId: $userId, token: $token) {
      last4
    }
  }
`;

export const ADD_MEMBER_MUTATION = `
  mutation addTeamMember($email: String!, $companyId: ID!) {
    addTeamMember(email: $email, companyId: $companyId)
  }
`;

export const SEND_SAMPLE_INVITE = `
  mutation s4tSampleInvite {
    s4tSampleInvite
  }
`;

export const DEL_MEMBER_MUTATION = `
  mutation delTeamMember($email: String!, $companyId: ID!) {
    delTeamMember(email: $email, companyId: $companyId)
  }
`;

export const RESEND_INVITE_MUTATION = `
  mutation resendTeamInvite($email: String!) {
    resendTeamInvite(email: $email)
  }
`;
  
export const SIGNUP_LITE_MUTATION = `
  mutation signupLite($email: String!, $password: String!, $first_name: String!, $last_name: String!, $type: String!) {
    signupLite(email: $email, password: $password, first_name: $first_name, last_name: $last_name, type: $type) {
      id
      jwt
    }
  }
`;

export const VERIFY_USER_MUTATION = `
  mutation verifyUser($code: String!) {
    verifyUser(code: $code)
  }
`;

export const RESEND_VERIFICATION_MUTATION = `
  mutation resendVerificationCode {
    resendVerificationCode
  }
`;

export const LOGIN_MUTATION = `
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      jwt
      type
      admin
    }
  }
`;

export const CREATE_COMPANY_MUTATION = `
  mutation createCompany($name: String!) {
    createCompany(name: $name) {
      id
      name
    }
  }
`;

export const EDIT_COMPANY_MUTATION = `
  mutation editCompany($companyId: ID!, $name: String!) {
    editCompany(companyId: $companyId, name: $name) {
      id 
      name
    }
  }
`;

export const CREATE_PLAN_MUTATION = `
  mutation createPoolPlan($companyId: ID!, $credits: Int!, $cost: Int!, $interval: String!, $quota: Int!, $period: Int) {
    createPoolPlan(companyId: $companyId, credits: $credits, cost: $cost, interval: $interval, quota: $quota, period: $period) {
      id
    }
  }
`;

export const EDIT_PLAN_MUTATION = `
  mutation editPlan($id: ID!, $data: String!) {
    editPlan(id: $id, data: $data) {
      id
    }
  }
`;

export const UPDATE_FIELD_MUTATION = `
  mutation updateProfileField($id: ID!, $field: String!, $value: String!) {
    updateProfileField(id: $id, field: $field, value: $value)
  }
`;

export const NEWSLETTER_SUBSCRIBE_MUTATION = `
  mutation newsletterSubscribe($name: String! $email: String!) {
    newsletterSubscribe(name: $name, email: $email)
  }
`;

export const CREATE_S4T_SBX = `
  mutation createS4TSbx($companyId: ID!, $planId: ID!) {
    createS4TSbx(companyId: $companyId, planId: $planId)
  }
`;

export const AGREE_TO_TERMS_MUTATION = `
  mutation storeS4Ttc($companyId: ID!, $title: String!) {
    storeS4Ttc(companyId: $companyId, title: $title)
  }
`;

export const FORGOT_PASSWORD_MUTATION = `
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;
