import { appRoutes, signupRoutes } from "./routes";
import InvalidRoute from "../components/InvalidRoute";
import Auth from "../screens/App/Auth";
import ForgotPassword from "../screens/App/ForgotPassword";
import Insights from "../screens/App/Insights";
import Welcome from "../screens/Signup/Welcome";
import GetStarted from "../screens/Signup/GetStarted";
import Verify from "../screens/Signup/Verify";
import TeamName from "../screens/Signup/TeamName";
import Corporate from "../screens/Signup/Corporate";
import PlanSetup from "../screens/Signup/PlanSetup";
import Billing from "../screens/Signup/Billing";
import Terms from "../screens/Signup/Terms";
import Confirm from "../screens/Signup/Confirm";
import Invite from "../screens/Signup/Invite";

export const APP_ROUTES = [
  {
    route: appRoutes.login,
    isProtected: false,
    Component: Auth,
  },
  {
    route: appRoutes.forgotPassword,
    isProtected: false,
    Component: ForgotPassword,
  },
  {
    route: appRoutes.insights,
    isProtected: true,
    Component: Insights,
  },
  {
    route: appRoutes.default,
    isProtected: false,
    Component: InvalidRoute,
  },
];

export const SIGNUP_ROUTES = [
  {
    route: signupRoutes.welcome,
    isProtected: false,
    Component: Welcome,
  },
  {
    route: signupRoutes.getstarted,
    isProtected: false,
    Component: GetStarted,
  },
  {
    route: signupRoutes.verify,
    isProtected: false,
    Component: Verify,
  },
  {
    route: signupRoutes.teamname,
    isProtected: true,
    Component: TeamName,
  },
  {
    route: signupRoutes.corporate,
    isProtected: true,
    Component: Corporate,
  },
  {
    route: signupRoutes.plansetup,
    isProtected: true,
    Component: PlanSetup,
  },
  {
    route: signupRoutes.billing,
    isProtected: true,
    Component: Billing,
  },
  {
    route: signupRoutes.terms,
    isProtected: true,
    Component: Terms,
  },
  {
    route: signupRoutes.confirm,
    isProtected: true,
    Component: Confirm,
  },
  {
    route: signupRoutes.invite,
    isProtected: true,
    Component: Invite,
  },
  {
    route: signupRoutes.default,
    isProtected: false,
    Component: InvalidRoute,
  },
];
