import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { useSignupNavigation, useCreatePlan, useEditPlan } from "../shared/hooks";
import { useTeam } from "../../../lib/hooks";
import { ButtonWrapper } from "../shared/styled";
import { plan, team, _costState } from "../../../store";
import { lengthOptions, scheduleOptions, termOptions } from "./options";

const CALENDLY_LINK = `https://calendly.com/spherecrew/sphere-for-teams-discovery-call`;

export const CTA = () => {
  const history = useHistory();
  const setPlan = useSetRecoilState(plan);
  const { schedule, term } = useRecoilValue(plan);
  const { id, size, plan: planDetails } = useRecoilValue(team);
  const { due, totalCredits } = useRecoilValue(_costState);
  const { createPlan, loading, error } = useCreatePlan();
  const { editPlan, loading: editLoading, error: editError } = useEditPlan();
  const { teamLoading, refetchTeam } = useTeam();
  const { nextRoute, hasCompletedStep, setCompletedStep } = useSignupNavigation();
  const [isLoading, setLoading] = useState(false);
  const [planVariables, setVariables] = useState({});
  const isMonthly = schedule.value === "Monthly";
  const hasPlan = Boolean(planDetails && planDetails.id);
  
  useEffect(() => {
    // use options selected from sphere.guide if available.
    const existingPlan = localStorage.getItem("plan");
    if (existingPlan && !hasCompletedStep) {
      const plan = existingPlan.split('-')
      const isMonthly = plan[0][0] === "m";
      const length = plan[0].substr(1);
      const term = plan[1].substr(1);
      setPlan((plan) => ({
        ...plan,
        // eslint-disable-next-line
        length: lengthOptions.find((item) => item.value == length),
        schedule: scheduleOptions[isMonthly ? 0 : 1],
        // eslint-disable-next-line
        term: termOptions.find((item) => item.value == term),
      }));
    }
  }, [hasCompletedStep, setPlan]);

  useEffect(() => {
    // teamLoading is in this component so we that we can update client
    // state w/ db values, and doesn't need to be included here
    setLoading([loading, editLoading].some(Boolean));
  }, [loading, editLoading]);

  useEffect(() => {
    setVariables({
      companyId: id,
      credits: totalCredits,
      cost: Math.ceil(due),
      period: term.value,
      quota: Number(size),
      interval: isMonthly ? "month" : "year",
    });
  }, [id, totalCredits, due, isMonthly, size, term]);

  const onCreate = useCallback(() => {
    createPlan({
      variables: planVariables,
    }).then(({ data }) => {
      if (data && data.createPoolPlan) {
        setCompletedStep();
        history.push(nextRoute);
      }
    });
  }, [createPlan, history, nextRoute, planVariables, setCompletedStep]);

  const onEdit = useCallback(() => {
    editPlan({
      variables: {
        id: planDetails.id,
        data: JSON.stringify(planVariables),
      },
    }).then(({ data }) => {
      if (data && data.editPlan) {
        refetchTeam();
        setCompletedStep();
        history.push(nextRoute);
      }
    });
  }, [
    editPlan,
    history,
    nextRoute,
    planDetails,
    planVariables,
    refetchTeam,
    setCompletedStep,
  ]);

  const onComplete = useCallback(() => {
    hasPlan ? onEdit() : onCreate();
  }, [hasPlan, onEdit, onCreate]);

  return (
    <ButtonWrapper>
      {(error || editError) && <Error error={error} />}
      <Button
        onClick={onComplete}
        disabled={isLoading || teamLoading}
        cta
      >
        {isLoading ? <LoadingSpinner loading={isLoading} /> : "Next"}
      </Button>
    </ButtonWrapper>
  )
};

export const CTAAlternate = () => (
  <ButtonWrapper>
    <Button
      onClick={() =>
        window.open(CALENDLY_LINK, "_blank", "noopener noreferrer")
      }
      disabled={false}
      cta
    >
      Book a Time
    </Button>
  </ButtonWrapper>
);
