import React, { useState, useEffect } from "react";
import { ClientContext, GraphQLClient } from "graphql-hooks";
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { pickUri, pickFirebase } from "../lib/uris";
import { useBounceToRoute } from "../components/Signup/shared/hooks";

// Firebase Config
firebase.initializeApp(pickFirebase());
// GraphQL Config
const url = pickUri();
const client = new GraphQLClient({ url });
const localToken = localStorage.getItem("token");
if (localToken) client.setHeader("Authorization", `Bearer ${localToken}`);

const withAuth = (WrappedComponent) => () => {
  const [isAuthenticated, setAuthenticated] = useState(null);
  const [token, setToken] = useState(localToken);
  const [loading, setLoading] = useState(false);
  const route = useBounceToRoute();

  useEffect(() => {
    client.onError = ({ result: { error } }) => {
      if (error?.graphQLErrors) {
        const err = error.graphQLErrors[0];
        console.info(err);
        if (err?.message === "Unauthorized request") {
          window.location.replace(route);
        }
      }
    }
  }, [route]);

  useEffect(() => {
    const interval = 60000;

    const observeToken = setInterval(() => {
      const tokenObj = token ? JSON.parse(atob(token.split(".")[1])) : {};

      if (
        // not expired
        Object.keys(tokenObj).length &&
        !(tokenObj.exp < Date.now() / 1000 + interval)
      ) {
        client.setHeader("Authorization", `Bearer ${token}`);
      } else {
        const user = firebase.auth().currentUser;
        if (user) {
          user.getIdToken(true).then((newToken) => {
            localStorage.setItem("token", newToken);
            client.setHeader("Authorization", `Bearer ${newToken}`);
          });
        } else {
          setAuthenticated(false);
          const refreshToken = localStorage.getItem("refreshToken");
          token && localStorage.removeItem("token");
          refreshToken && localStorage.removeItem("refreshToken");
        }
      }
    }, interval);

    if (!token) {
      firebase.auth().signOut();
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    }

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setAuthenticated(Boolean(user));
    });

    return () => {
      unsubscribe();
      clearInterval(observeToken);
    };
  }, [token]);

  const _handleLoginWithFirebase = async (jwt, cb) => {
    setLoading(true);
    firebase
      .auth()
      .signInWithCustomToken(jwt)
      .then(({ user }) => {
        if (user) {
          user
          .getIdToken()
          .then((firebaseToken) => {
              const { refreshToken } = user;
              localStorage.setItem("token", firebaseToken);
              localStorage.setItem("refreshToken", refreshToken);
              client.setHeader("Authorization", `Bearer ${firebaseToken}`);
              setToken(firebaseToken);
              setLoading(false);
              return firebaseToken;
            })
            .then((token) => {
              if (cb && token) cb();
            })
            .catch((error) => {
              setLoading(false);
              console.log("getIdToken error", error);
            });
        }
      })
      .catch((e) => console.log("ERROR SIGNING IN WITH CUSTOM TOKEN:", e));
  };

  return (
    <ClientContext.Provider value={client}>
      <WrappedComponent
        isAuthenticated={isAuthenticated}
        loginWithFirebase={_handleLoginWithFirebase}
        setToken={setToken}
        loading={loading}
      />
    </ClientContext.Provider>
  );
};

export default withAuth;
