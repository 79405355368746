import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useSetRecoilState, useRecoilValue } from "recoil";
import TextInput from "../../TextInput";
import Dropdown from "./Dropdown";
import { capitalize } from "../../../lib/helpers";
import { SubHeading } from "../../Typography";
import { team, _teamState } from "../../../store";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { regions } from "./geographicRegions";
import { Container, FormGrid } from "../shared/styled";

const _propTypes = {
  unavailable: PropTypes.bool.isRequired,
};

export const Form = ({ unavailable }) => {
  const setTeam = useSetRecoilState(team);
  const teamState = useRecoilValue(_teamState);
  const { email, country, province } = useRecoilValue(team);

  const DROPDOWN_OPTIONS = regions[country.value];

  const onChangeItem = useCallback(
    (item, value) => {
      if (value === "country") {
        setTeam((team) => ({
          ...team,
          [value]: item,
          province: regions[item.value][1],
          postalCode: "",
        }));
      }

      setTeam((team) => ({
        ...team,
        [value]: item,
      }));
    },
    [setTeam]
  );

  const onChangeText = useCallback(
    (e, type) => {
      e.preventDefault();
      const { target: { value } } = e;

      if (type === "postalCode") {
        const item = inputFieldOptions.corporate.find(
          ({ value }) => value === "postalCode"
        );
        const maxLimit = item.attrs.minLength(country.value);
        if (value.length <= maxLimit) {
          setTeam((team) => ({
            ...team,
            [type]: value.toUpperCase(),
          }));
        }
      } else {
        setTeam((team) => ({
          ...team,
          [type]: capitalize(value),
        }));
      }
    },
    [setTeam, country]
  );

  return (
    <Container>
      {unavailable ? (
        <TransitionGroup component={null}>
          <CSSTransition classNames="fade" timeout={300}>
            <div style={{ margin: "20px 0 30px" }}>
              <SubHeading style={{ whiteSpace: "normal" }}>
                <span>
                  {`Sphere is currently available in Canada and the United States only. 
                    Click below to add ${email} to our mailing list and be notified 
                    when we expand to other markets.`}
                </span>
              </SubHeading>
            </div>
          </CSSTransition>
        </TransitionGroup>
      ) : (
        <TransitionGroup>
          <CSSTransition classNames="fade" timeout={300}>
            <FormGrid>
              {inputFieldOptions.corporate.map(
                ({ id, dropdown, value, attrs }) => {
                  if (value === "country") {
                    return (
                      <Dropdown
                        key={id}
                        options={regions.countryOptions}
                        value={country.label}
                        style={{ gridColumn: "span 2 / auto" }}
                        type="Country"
                        onClick={(item) => onChangeItem(item, value)}
                      />
                    );
                  }
                  if (value === "postalCode") {
                    return (
                      <TextInput
                        key={id}
                        attrs={{
                          ...attrs,
                          minLength: attrs.minLength(country.value),
                          placeholder: attrs.placeholder(country.value),
                        }}
                        value={teamState[value]}
                        onChange={(e) => onChangeText(e, value)}
                        style={{ gridColumn: "span 1 / auto" }}
                      />
                    );
                  }

                  return dropdown ? (
                    <Dropdown
                      key={id}
                      options={DROPDOWN_OPTIONS}
                      value={province.label}
                      style={{ gridColumn: "span 1 / auto" }}
                      type={attrs.placeholder(country.value)}
                      onClick={(item) => onChangeItem(item, value)}
                    />
                  ) : (
                    <TextInput
                      key={id}
                      attrs={attrs}
                      value={teamState[value]}
                      onChange={(e) => onChangeText(e, value)}
                      style={{ gridColumn: "span 2 / auto" }}
                    />
                  );
                }
              )}
            </FormGrid>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Container>
  )
};
Form.propTypes = _propTypes;
