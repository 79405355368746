
export const lengthOptions = [
  { value: 30, label: "30min" },
  { value: 60, label: "60min" },
  { value: 120, label: "120min" },
].map((item, index) => {
  item.id = index;
  return item;
});

export const termOptions = [
  { value: 12, label: "12 Months", preferred: true }, // reversing order because selector detail bubble borks the styles on mobile
  { value: 6, label: "6 Months" },
  { value: 3, label: "3 Months" },
].map((item, index) => {
  item.id = index;
  return item;
});

export const scheduleOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Up-front", label: "Up-front", preferred: true },
].map((item, index) => {
  item.id = index;
  return item;
});
