import React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import Selector from "./Selector";
import copy from "../shared/communications";
import { Body, SectionHeading, FinePrint } from "../../Typography";
import { DetailRow } from "../shared/styled";
import { plan, _costState, _teamState } from "../../../store";
import { lengthOptions, termOptions, scheduleOptions } from "./options";

const { finePrint } = copy;

const _propTypes = {
  Length: {
    onChange: PropTypes.func.isRequired,
  },
  Commitment: {
    onChange: PropTypes.func.isRequired,
  },
  Billing: {
    onChange: PropTypes.func.isRequired,
  },
};

export const Length = ({ onChange }) => {
  const { length } = useRecoilValue(plan);
  return (
    <>
      <SectionHeading>Coaching Session Length</SectionHeading>
      <FinePrint><span>{finePrint.planSetup}</span></FinePrint>
      <Selector
        options={lengthOptions}
        onClick={(item) => onChange(item, "length")}
        selectedItem={length}
      />
      <div style={{ marginTop: 30 }} />
    </>
  )
};
Length.propTypes = _propTypes.Length;

export const Commitment = ({ onChange }) => {
  const { term } = useRecoilValue(plan);
  return (
    <>
      <SectionHeading>Coaching Commitment</SectionHeading>
      <Selector
        options={termOptions}
        onClick={(item) => onChange(item, "term")}
        selectedItem={term}
      />
      <div style={{ marginTop: 30 }} />
    </>
  )
};
Commitment.propTypes = _propTypes.Commitment;

export const Billing = ({ onChange }) => {
  const { schedule } = useRecoilValue(plan);
  return (
    <>
      <SectionHeading>Billing Schedule</SectionHeading>
      <Selector
        options={scheduleOptions}
        onClick={(item) => onChange(item, "schedule")}
        selectedItem={schedule}
      />
      <div style={{ marginTop: 30 }} />
    </>
  )
};
Billing.propTypes = _propTypes.Billing;

export const Payment = () => {
  const { country } = useRecoilValue(_teamState);
  const { tax, due, discount } = useRecoilValue(_costState);
  const { schedule } = useRecoilValue(plan);
  const isMonthly = schedule.value === "Monthly";

  return (
    <>
      <DetailRow>
        <Body>{isMonthly ? "Monthly Cost" : "Cost"}</Body>
        <Body>{`$${due}`}</Body>
      </DetailRow>

      {country.value === "CA" && (
        <DetailRow underline>
          <Body>Tax</Body>
          <Body>{`$${tax}`}</Body>
        </DetailRow>
      )}

      {/* eslint-disable-next-line */}
      {discount != 0 && (
        <DetailRow>
          <Body>Discount Applied</Body>
          <Body>
            {`-${discount}%`}
          </Body>
        </DetailRow>
      )}
    </>
  )
};