import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { ButtonWrapper } from "../shared/styled";
import { useSignupNavigation, useSignupLite } from "../shared/hooks";
import { isEmailValid } from "../../../lib/helpers";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { _userState } from "../../../store";

const _propTypes = {
  loginWithFirebase: PropTypes.func.isRequired,
};

export const CTA = ({ loginWithFirebase }) => {
  const history = useHistory();
  const userState = useRecoilValue(_userState);
  const { userId, firstName, lastName, email, password } = userState;
  const [disabled, setDisabled] = useState(true);
  const [existingEmail, setExistingEmail] = useState(email);
  const { nextRoute, hasCompletedStep, setCompletedStep } = useSignupNavigation();
  const { signup, loading, error } = useSignupLite(
    email,
    password,
    firstName,
    lastName
  );

  useEffect(() => {
    if (userId) setCompletedStep();
  }, [setCompletedStep, userId]);

  useEffect(() => {
    if (hasCompletedStep && email !== existingEmail) {
      setExistingEmail(email);
    }
  }, [email, existingEmail, hasCompletedStep, setExistingEmail]);

  useEffect(() => {
    setDisabled(
      hasCompletedStep
      ? false
      : inputFieldOptions.getStarted
          .map(({ attrs, value }) => {
            if (value === "email") return !isEmailValid.test(email);
            return userState[value].length < attrs.minLength;
          })
          .some(Boolean)
    );
  }, [email, userState, hasCompletedStep]);

  const onSignup = useCallback(() => {
    signup().then(({ data }) => {
      if (data) {
        const { signupLite: { jwt, email } } = data;
        const onSuccess = () => {
          setExistingEmail(email);
          setCompletedStep();
          history.push(nextRoute);
        };
        loginWithFirebase(jwt, onSuccess);
      }
    });
  }, 
    [
      history,
      loginWithFirebase,
      nextRoute,
      setCompletedStep,
      setExistingEmail,
      signup,
    ]
  );

  const onClick = useCallback(() => {
    hasCompletedStep ? history.push(nextRoute) : onSignup();
  }, [hasCompletedStep, history, nextRoute, onSignup]);

  return (
    <ButtonWrapper>
      {error && <Error error={error} />}
      <Button
        onClick={onClick}
        disabled={hasCompletedStep ? false : disabled || loading}
        loading={loading}
        cta
      >
        {loading ? <LoadingSpinner loading={loading} /> : "Next"}
      </Button>
    </ButtonWrapper>
  )
};
CTA.propTypes = _propTypes;
