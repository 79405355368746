import React from "react";
import styled from 'styled-components';

// Components
import Button from './Button';
import ListItem from './ListItem';
import MenuDetail from './MenuDetail';
import MenuHeader from'./MenuHeader';
import MenuControls from './MenuControls';

const Account = ({ 
  setToken,
  setShowModal,
  showTeam,
  setShowTeam,
  showBilling,
  setShowBilling,
  showReports,
  setShowReports,
  setCurrentMember,
  company
}) => (
  <Container>
    <MenuHeader onClose={() => setShowModal(false)}>
      Your Team Account
    </MenuHeader>
    <ListItem
      title={company.name}
      detail={company.manager && company.manager.email}
      onClick={() => {
        if (showTeam) {
          setShowTeam(false);
          setCurrentMember(null);
        } else setShowTeam(true);
      }}
      isOpen={showTeam}
    />
    <ListItem
      title="Billing"
      detail={(company.paymentMethod && company.paymentMethod.length && company.paymentMethod[0].last4) ? `${company.paymentMethod[0].brand.toUpperCase()} ending in ${company.paymentMethod[0].last4}` : 'No card'}
      onClick={() => setShowBilling(prev => !prev)}
      isOpen={showBilling}
    />
    {
      !!company.reports &&
        <ListItem
          title="Reports"
          detail={(company.reports.length) ? `${company.reports.length} reports` : 'No Reports'}
          onClick={() => setShowReports(prev => !prev)}
          isOpen={showReports}
        />
    }
    <MenuDetail
      title="Current Credits"
      value={company.credits || 0}
    />
    <MenuDetail
      title="Next Billing"
      value={company.nextDeposit}
    />
    <MenuDetail
      title="Monthly Cost"
      value={"$"+(company.plan && Math.round(company.plan.cost / 100))}
    />
    <MenuDetail
      title="Monthly Credits"
      value={company.plan && company.plan.credits}
    />
    <MenuDetail
      title="Max Credit Rollover"
      value={company.plan && company.plan.max_rollover}
    />
    {company && company.redeemed && company.invites && 
      <MenuDetail
        title="Remaining Invites"
        value={company.userQuota - company.invites.length - company.redeemed.length}
      />
    }
    <MenuControls>
      <Button style={{marginBottom: 10}} onClick={() => window.location.href = "mailto:support@sphereishere.com?subject=Sphere%20For%20Teams%20Support"} cta={false}>
        Contact Support
      </Button>
      <Button onClick={() => setToken(null)} cta={false}>
        Logout
      </Button>
    </MenuControls>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .buttons {
    height: 90px;
  }
`;

export default Account;
