import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "../../Button";
import variables from "../../../variables";
import { ButtonWrapper } from "../shared/styled";
import { Body } from "../../Typography";

const checkmark = require("../../../images/check_blue@3x.png");

const { breakpoints: { small } } = variables;

const _propTypes = {
  CheckmarkAnimated: {
    hasCompletedStep: PropTypes.bool.isRequired,
    inProp: PropTypes.bool.isRequired,
  },
  Link: {
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  },
  LinkItem: {
    item: PropTypes.object.isRequired,
    isCheckmarkVisible: PropTypes.bool.isRequired,
  },
  ListItem: {
    text: PropTypes.string.isRequired,
    isCheckmarkVisible: PropTypes.bool.isRequired,
  },
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:   { opacity: 0 },
};

const CheckmarkAnimated = ({ inProp, hasCompletedStep }) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (inProp) setTimeout(() => setVisible(true), hasCompletedStep ? null : 150);
  }, [inProp, hasCompletedStep]);

  return (
    <Transition in={isVisible} timeout={300}>
      {state => (
        <Checkmark state={state} />
      )}
    </Transition>
  )
};

export const Link = ({ href, text }) => (
  <Body>
    &nbsp;<a href={href}>{text}</a>
  </Body>
);

export const LinkItem = ({ item, isCheckmarkVisible, ...props }) => (
  <Container>
    <CheckmarkAnimated inProp={isCheckmarkVisible} {...props} />
    <Body style={{ display: "flex", flexDirection: "row" }}>
      {item.text}
      <Link href={item.href} text={item.linkText} />
    </Body>
  </Container>
);

export const ListItem = ({ text, isCheckmarkVisible, ...props }) => (
  <Container>
    <CheckmarkAnimated inProp={isCheckmarkVisible} {...props} />
    <Body>{text}</Body>
  </Container>
);

export const GetStarted = ({ ...props }) => (
  <StyledButton {...props}>
    <Button cta {...props}>
      Get Started
    </Button>
  </StyledButton>
);

export const Login = ({ ...props }) => (
  <StyledLogin {...props}>
    <Body>or&nbsp;</Body>
    <Body style={{ textDecoration: 'underline', cursor: 'pointer' }}>Login</Body>
  </StyledLogin>
);

GetStarted.displayName = "GetStarted";
Link.displayName = "FragmentLink";
LinkItem.displayName = "FragmentLinkItem";
ListItem.displayName = "FragmentListItem";
Login.displayName = "Login";

CheckmarkAnimated.propTypes = _propTypes.CheckmarkAnimated;
Link.propTypes = _propTypes.Link;
LinkItem.propTypes = _propTypes.LinkItem;
ListItem.propTypes = _propTypes.ListItem;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const Checkmark = styled.div`
  background: center / contain no-repeat url(${checkmark});
  color: ${({ theme }) => theme.fg};
  height: 25px;
  justify-content: center;
  margin-right: 10px;
  opacity: 0;
  transition: opacity 1000ms ease;
  width: 25px;
  ${({ state }) => transitionStyles[state]};
`;

const StyledButton = styled(ButtonWrapper)`
  height: min-content;
  width: 100%;
`;

const StyledLogin = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.fg};
  display: flex;
  flex-direction: column;
  height: min-content;
  justify-content: center;
  margin: 10px auto 0 auto;
  max-width: ${({ isVisible }) => (isVisible ? "100px" : 0)};
  opacity: ${({ isVisible }) => Boolean(isVisible)};
  overflow: hidden;
  transition: all 350ms ease-out;
  width: ${({ isVisible }) => (isVisible ? "100px" : 0)};
  white-space: nowrap;
  & > * {
    text-align: center;
  }

  @media(min-width: ${small}px) {
    align-self: flex-end;
    flex-direction: row;
    margin: 0 0 0 10px;
  }
`;
