import React from "react";
import Header from "../../components/Signup/shared/SignupHeader";
import Layout from "../../components/Signup/shared/Layout";
import { SubHeading } from "../../components/Typography";
import { appRoutes } from "../../navigation/routes";
import Form from "../../components/App/ForgotPassword/Form";
import CTA from "../../components/App/ForgotPassword/CTA";

const { login } = appRoutes;

const title = `Forgot your password?\nNo biggie.`;

const ForgotPassword = () => (
  <>
    <Header />
    <Layout title={title} hasPrev={true} prevRoute={login}>
      <SubHeading>Enter your email and we'll get you sorted.</SubHeading>
      <Form />
      <CTA />
    </Layout>
  </>
);

ForgotPassword.propTypes = {};

export default ForgotPassword;
