import variables from "../variables";

export default {
  dark: {
    name: "dark",
    bg: variables.black,
    bg_offset: variables.black_primary,
    bg_secondary: variables.black_secondary,
    fg: variables.white,
    fg_secondary: variables.grey_primary,
    brand_primary: variables.blue_primary,
    error: variables.error,
  },
  light: {
    name: "light",
    bg: variables.white,
    bg_offset: variables.light_grey,
    bg_secondary: variables.light_grey,
    fg: variables.black,
    fg_secondary: variables.medium_grey,
    brand_primary: variables.blue_primary,
    error: variables.error,
  }
};
