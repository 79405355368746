import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { FinePrint } from "../../Typography";
import { useInviteMember } from "../shared/hooks";
import { useTeam } from "../../../lib/hooks";
import { ButtonWrapper } from "../shared/styled";
import { _inviteState, team } from "../../../store";

const _propTypes = {
  sampleLoading: PropTypes.bool,
  setSignup: PropTypes.func.isRequired,
};

export const CTA = ({ sampleLoading, setSignup }) => {
  const [isLoading, setLoading] = useState(false);
  const { invite, loading, error } = useInviteMember();
  const { canInvite } = useRecoilValue(_inviteState);
  const { teamLoading, teamData } = useTeam();
  const { id, invites } = useRecoilValue(team);

  useEffect(() => {
    setLoading([loading, sampleLoading].some(Boolean));
  }, [loading, sampleLoading]);

  const completeSignup = useCallback(() => {
    if (localStorage.getItem("plan")) {
      localStorage.removeItem("plan");
    }
    if (localStorage.getItem("lastCompletedStep")) {
      localStorage.removeItem("lastCompletedStep");
    }
    setSignup(false);
    window.location.reload(); 
  }, [setSignup]);

  const onInvite = useCallback(() => {
    const { getTeam: { invites: i } } = teamData;
    const dbInvites = i.map(invite => invite.email);
    const inviteSet = new Set(invites); // client state
    // don't invite emails that have already been invited on subsequent attempts
    invites.forEach(invite => {
      if (dbInvites.includes(invite)) {
        inviteSet.delete(invite);
      }
    });
    const dedupedInvites = Array.from(inviteSet);

    if (!dedupedInvites.length) {
      // this should never happen in production (as long as /invite is the exit route), but useful in development
      completeSignup();
    } else {
      invite({
        variables: {
          email: dedupedInvites.length > 1 ? JSON.stringify(dedupedInvites) : dedupedInvites[0],
          companyId: id
        }
      })
      .then(({ data }) => {
        const { addTeamMember } = data;
        if (addTeamMember) {
          // at this point the route stack is switched out, so no need 
          // to push to next route since the redirect will handle it.
          completeSignup();
        }
      })
    }
  }, 
    [
      id, 
      invite, 
      invites,
      completeSignup,
      teamData
    ]
  );

  return (
    <ButtonWrapper style={{ marginBottom: invites.length >= 10 && 200 }}>
      {error && <Error error={error} />}
      <Button onClick={onInvite} disabled={!canInvite || isLoading || teamLoading} cta>
        {isLoading ? <LoadingSpinner loading={isLoading} /> : "Add Teammates"}
      </Button>
      <FinePrint onClick={completeSignup} style={{ alignSelf: "center", marginTop: 20 }}>
        {/* eslint-disable-next-line */}
        <a>Do this later</a>
      </FinePrint>
    </ButtonWrapper>
  )
};
CTA.propTypes = _propTypes;
