import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const _propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

const Selector = ({ options, onClick, selectedItem }) => (
  <SelectorContainer>
    {options.map((item, index) => {
      const selected = selectedItem.id === index;
      return (
        <div key={item.id} style={{ position: 'relative' }}>
          {selected && item.preferred && (
            <DetailBubble>
              Best Rate
            </DetailBubble>
          )}
          <SelectorPill
            selected={selected}
            onClick={() => onClick(item)}
          >
            {item.label}
          </SelectorPill>
        </div>
      )
    })}
  </SelectorContainer>
);

Selector.propTypes = _propTypes;

export default Selector;

const SelectorContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const SelectorPill = styled.div`
  background: ${({ theme, selected }) => (selected ? theme.fg : theme.bg_secondary)};
  border-radius: 20px;
  color: ${({ theme, selected }) => (selected ? theme.bg : theme.fg)};
  cursor: ${({ selected }) => selected ? 'auto' : 'pointer'};
  display: flex;
  font-size: 24px;
  margin: 5px 5px 0 0;
  padding: 6px 10px;
`;

const DetailBubble = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.brand_primary};
  color: ${({ theme }) => theme.fg};
  font-size: 10px;
  position: absolute;
  top: -13px;
  right: 0;
  padding: 5px;
  z-index: 3;
`;
