import React from "react";
import variables from "../variables";
import styled from "styled-components";

const MenuHeader = ({ children, onClose, error }) => (
  <Header error={error}>
    <span>{children}</span>{" "}
    {onClose && <Cross onClick={onClose}>&times;</Cross>}
  </Header>
);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 30px;
  color: ${(props) =>
    props.error ? variables.error : variables.white_primary};
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 17px;
    word-break: break-word;
  }
`;

const Cross = styled.span`
  font-size: 1.7em;
  cursor: pointer;
  line-height: 30px;
`;

export default MenuHeader;
