import React from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import withStripeElements from "../../components/Signup/Billing/withStripeElements";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { SubHeading } from "../../components/Typography";
import { CTA } from "../../components/Signup/Billing/CTA";
import { Form } from "../../components/Signup/Billing/Form";

const { titles, subHeadings } = copy;

const Billing = () => {
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.billing} hasPrev={hasPrev} prevRoute={prevRoute}>
      <SubHeading><span>{subHeadings.billing}</span></SubHeading>
      <Form />
      <CTA />
    </Layout>
  );
};

export default withStripeElements(Billing);
