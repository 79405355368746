import React from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { CTA } from "../../components/Signup/TeamName/CTA";
import { Form } from "../../components/Signup/TeamName/Form";

const { titles } = copy;

const TeamName = () => {
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.teamName} hasPrev={hasPrev} prevRoute={prevRoute}>
      <Form />
      <CTA /> 
    </Layout>
  );
};
export default TeamName;
