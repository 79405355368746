export const appRoutes = {
  login: '/login',
  forgotPassword: '/forgotpassword',
  insights: '/insights',
  default: "*",
};

export const signupRoutes = {
  welcome: '/welcome',
  getstarted: '/getstarted',
  verify: '/verify',
  teamname: '/teamname',
  corporate: '/corporate',
  plansetup: '/plansetup',
  billing: '/billing',
  terms: '/terms',
  confirm: '/confirm',
  invite: '/invite',
  default: "*",
}
