export default (credits, day, month, year, cost, country, term, isMonthly) => ([
  `The Client will have access to a total of ${credits} credits / per month;`,
  `The Client’s Ocean Plan subscription will begin on the ${day} day of ${month}, ${year} ${isMonthly ? `[also the first day of billing]` : ``} at a base rate of $${cost}${country.value === "CA" ? ` + GST` : ``}${isMonthly ?  ` / month;` : `;`}`,
  `The Client’s Ocean Plan is a ${term} month commitment, billed ${isMonthly ? `monthly` : `up-front`}, and is non-refundable;`,
  `Up to 50% of the total available credits in a cycle may roll-over for a maximum of 30 days from the end of the cycle, if unused;`,
  `All Ocean Plan credits are transferable within the team, and can be accessed by any team member who has been invited to the Client’s Ocean Plan, via the Client’s Insights Portal;`,
  `The Client reserves the right to add new team members to the plan at any time and can do so via the Insights Portal;`,
  `Additional credits can be purchased and added to the Client’s Ocean Plan at anytime by The Client’s admin, directly through the Insights Portal;`,
  `The Client has the ability to add redemption caps at any time to manage consumption and can do so by contacting their dedicated Sphere Experience Captain. `,
]);
