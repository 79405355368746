import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { useRecoilValue } from "recoil";
import { Body } from "../../Typography";
import { CTA } from "../../Signup/Terms/CTA";
import { Form, CenterContent } from "../../Signup/Terms/Form";
import { Gradient } from "../../Signup/shared/styled";
import { useTeam, useCurrentUser } from "../../../lib/hooks";
import { capitalize } from "../../../lib/helpers";
import { team, user, _oceanPlanState } from "../../../store";
import listItems from "./listItems";
import variables from "../../../variables";

const { breakpoints: { medium } } = variables;

const sphereDetails = `STELLAR GUIDANCE TECHNOLOGIES INC., a corporation having a registered address at 2900 - 550 Burrard Street, Vancouver, BC, V6C 0A3`;

const transitionDuration = 300;

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:   { opacity: 0 },
};

const _propTypes = { 
  // adding this prop so we can display the read-only agreement in the portal at a later time
  showCTA: PropTypes.bool, 
};

const Agreement = ({ showCTA = true }) => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setLoading] = useState(true); // true on mount to avoid flashing content before data is ready
  const { teamLoading } = useTeam();
  const { userLoading } = useCurrentUser();
  const { firstName, lastName } = useRecoilValue(user);
  const { cost, credits, commitment, isMonthly } = useRecoilValue(_oceanPlanState);
  const {
    id,
    acceptedTerms,
    adminName,
    adminTitle,
    country,
    corporateName,
    corporateAddress1,
    corporateAddress2,
    city,
    province,
    postalCode,
    plan,
  } = useRecoilValue(team);

  const nameToValidate = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`;
  const date = acceptedTerms ? moment(Number(acceptedTerms)) : moment(new Date());
  const day = date.format("Do");
  const month = date.format("MMMM");
  const year = date.format("YYYY");

  useEffect(() => {
    setLoading([teamLoading, userLoading].some(Boolean));
  }, [teamLoading, userLoading, setLoading]);

  useEffect(() => {
    if (acceptedTerms) {
      setName(capitalize(adminName));
      setTitle(capitalize(adminTitle));
    }
  }, [acceptedTerms, adminName, adminTitle])

  return (
    <Container>
      <Body>{`THIS AGREEMENT MADE ON THE ${day} day of ${month}, ${year}.`}</Body>

      <Body>BETWEEN:</Body>
      <CenterContent>
        <Body>{sphereDetails}</Body>
      </CenterContent>

      <Transition in={!isLoading} timeout={transitionDuration}>
        {state => (
          <TransitionContainer state={state}>
            <Body>AND:</Body>
            <CenterContent>
              <Body>
                {`
                  ${
                    corporateName &&
                    `${corporateName.toUpperCase()}, ( The "Client" ) holding an address at `
                  }
                  ${
                    corporateAddress2 && capitalize(corporateAddress2) + " - "
                  }${capitalize(corporateAddress1)}${
                  city && `, ${capitalize(city)}`
                  }${province.label && `, ${capitalize(province.label)}`}${
                    postalCode && `, ${postalCode.toUpperCase()}`
                  }
                `}
              </Body>
            </CenterContent>

            <Body style={{ textDecoration: "underline", marginBottom: 0 }}>
              <b>Sphere For Teams "Ocean Plan" Subscription Details:</b>
            </Body>
            <TermsList>
              {plan && listItems(
                credits,
                day,
                month,
                year,
                cost,
                country,
                commitment,
                isMonthly
              ).map((item, i) => (
                <ListItem key={i}>{item}</ListItem>
              ))}
            </TermsList>

            <Body>{`The Client's Ocean Plan subscription is billed in ${
              country.value === "CA" ? `CAD` : `USD`
            } via Stripe`}</Body>

            <Body>
              By signing this agreement The Client commits to all details outlined
              above, and both understands and agrees to our Terms of Service and
              Privacy Policy.
            </Body>

            <Body>
              The signer confirms that they possess the right to bind the Client to
              the Sphere for Teams ‘Ocean Plan’ subscription.
            </Body>

            <Form 
              acceptedTerms={Boolean(acceptedTerms)}
              nameToValidate={nameToValidate}
              name={name}
              title={title}
              setName={setName}
              setTitle={setTitle}
            />

            {showCTA && (
              <CTA 
                acceptedTerms={Boolean(acceptedTerms)}
                companyId={id}
                name={name}
                nameToValidate={nameToValidate}
                title={title}
              />
            )}
          </TransitionContainer>
        )}
      </Transition>
      <Gradient />
    </Container>
  );
};

Agreement.propTypes = _propTypes;

export default Agreement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  position: relative;
  width: 100%;

  & > ${Body} {
    margin-bottom: 40px;
  }

  @media (min-width: ${medium}px) {
    // break out of layout and center
    width: 150%;
    margin-left: -25%;
    margin-bottom: 200px;
  }
`;

const TransitionContainer = styled(Container)`
  opacity: 0;
  transition: opacity ${transitionDuration}ms ease-out;
  ${({ state }) => transitionStyles[state]};

  @media (min-width: ${medium}px) {
    // reset
    width: auto;
    margin-left: auto;
    margin-bottom: auto;
  }
`;

const TermsList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  color: ${({ theme }) => theme.fg};
  margin: 10px 0;
`;
