const uris = {
  development: "https://api.staging.sphere.guide/graphql",
  staging: "https://api.staging.sphere.guide/graphql",
  production: "https://api.sphere.guide/graphql",
};

const stripe = {
  development: "pk_test_pYNqvxi9m3UlIKpg1FMmK1J0",
  staging: "pk_test_pYNqvxi9m3UlIKpg1FMmK1J0",
  production: "pk_live_B5HTcb0olG5oyRegO5cvLC7l",
};

const firebase = {
  production: {
    apiKey: "AIzaSyB7DNVM19IrLPBao0cGAFlvopPeR9xpBDA",
    authDomain: "sphere-86dd8.firebaseapp.com",
    databaseURL: "https://sphere-86dd8.firebaseio.com",
    projectId: "sphere-86dd8",
    storageBucket: "sphere-86dd8.appspot.com",
    messagingSenderId: "117906963069",
    appId: "1:117906963069:web:6b7822730df98a5ea2cf14",
  },
  staging: {
    apiKey: "AIzaSyBDlj9R6562COtq3PNyeGmM9TY-hgF0aDI",
    authDomain: "sphere-46642.firebaseapp.com",
    databaseURL: "https://sphere-46642.firebaseio.com",
    projectId: "sphere-46642",
    storageBucket: "sphere-46642.appspot.com",
    messagingSenderId: "1060118281603",
    appId: "1:1060118281603:web:71267b4f0ac56d1fa14ab6",
  },
  development: {
    apiKey: "AIzaSyBDlj9R6562COtq3PNyeGmM9TY-hgF0aDI",
    authDomain: "sphere-46642.firebaseapp.com",
    databaseURL: "https://sphere-46642.firebaseio.com",
    projectId: "sphere-46642",
    storageBucket: "sphere-46642.appspot.com",
    messagingSenderId: "1060118281603",
    appId: "1:1060118281603:web:71267b4f0ac56d1fa14ab6",
  },
};

const pickEnv = () => {
  let env;
  switch (window.location.hostname) {
    case "localhost":
      env = "development";
      break;
    case "staging.sphereforteams.com":
      env = "staging";
      break;
    case "sphereforteams.com":
      env = "production";
      break;
    default:
      env = "production";
      break;
  }

  return env;
};

module.exports = {
  pickUri: () => {
    const custom = localStorage.getItem("customUrl");
    const env = pickEnv();
    return custom || uris[env];
  },
  pickStripe: () => {
    const env = pickEnv();
    return stripe[env];
  },
  pickFirebase: () => {
    const env = pickEnv();
    return firebase[env];
  },
};
