import { useCallback } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { useMutation } from "graphql-hooks";
import { useLocation } from "react-router-dom";
import { useCreateMutationHook } from "../../../lib/helpers";
import { signupRoutes, appRoutes } from "../../../navigation/routes";
import { completedSignupSteps } from "../../../store";
import {
  ADD_MEMBER_MUTATION,
  ADD_PM_MUTATION,
  AGREE_TO_TERMS_MUTATION,
  CREATE_COMPANY_MUTATION,
  CREATE_PLAN_MUTATION,
  CREATE_S4T_SBX,
  EDIT_COMPANY_MUTATION,
  EDIT_PLAN_MUTATION,
  LOGIN_MUTATION,
  NEWSLETTER_SUBSCRIBE_MUTATION,
  RESEND_VERIFICATION_MUTATION,
  SEND_SAMPLE_INVITE,
  SIGNUP_LITE_MUTATION,
  UPDATE_FIELD_MUTATION,
  VERIFY_USER_MUTATION,
} from "../../../network/mutations";

export const useAddPaymentMethod = () => useCreateMutationHook('addPaymentMethod', ADD_PM_MUTATION);

export const useCreateCompany = () => useCreateMutationHook('createCompany', CREATE_COMPANY_MUTATION);

export const useCreatePlan = () => useCreateMutationHook('createPlan', CREATE_PLAN_MUTATION);

export const useCreateSbx = () => useCreateMutationHook('createSbx', CREATE_S4T_SBX);

export const useEditCompany = () => useCreateMutationHook('editCompany', EDIT_COMPANY_MUTATION);

export const useEditPlan = () => useCreateMutationHook('editPlan', EDIT_PLAN_MUTATION);

export const useInviteMember = () => useCreateMutationHook('invite', ADD_MEMBER_MUTATION);

export const useNewsletterSubscribe = () => useCreateMutationHook('newsletterSubscribe', NEWSLETTER_SUBSCRIBE_MUTATION);

export const useResendVerification = () => useCreateMutationHook('resend', RESEND_VERIFICATION_MUTATION);

export const useSendSampleInvite = () => useCreateMutationHook('sendSampleInvite', SEND_SAMPLE_INVITE);

export const useAgreeToTerms = () => useCreateMutationHook('agreeToTerms', AGREE_TO_TERMS_MUTATION);

export const usePreselectedPlan = () => {
  const [preselectedPlan, setPreselectedPlan] = useState(null);

  useEffect(() => {
    // A potential S4T user can select options on sphere.guide and
    // be brought to sphereforteams. Here we check for those options
    // and use them to determine if we should show the signup flow
    const existingPlan = localStorage.getItem("plan");
    const queryString = window.location.search;
    if (existingPlan) setPreselectedPlan(existingPlan);
    
    if (queryString) {
      const newPlan = queryString.split("=")[1];
      localStorage.setItem("plan", newPlan);

      if (existingPlan) {
        localStorage.removeItem("plan");
        localStorage.setItem("plan", newPlan);
      }

      setPreselectedPlan(newPlan);
    }

    return () => {
      // if (existingPlan) localStorage.removeItem("plan");
    };
  }, []);

  return [preselectedPlan];
};

export const useSignupLite = (email, password, first_name, last_name) => {
  const [signup, { data, loading, error }] = useMutation(SIGNUP_LITE_MUTATION, {
    variables: {
      email: email.trim(),
      password,
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      type: "Company",
    },
  });

  return {
    signup,
    data,
    loading,
    error,
  };
};

export const useSignupNavigation = () => {
  const completedSteps = useRecoilValue(completedSignupSteps);
  const setStep = useSetRecoilState(completedSignupSteps);
  const routes = Array.from(Object.keys(signupRoutes)).map(
    (entry) => `/${entry.toLowerCase()}`
  );
  const pathName = useLocation().pathname.replace(/[/]/g, "");
  const currentIndex = routes.indexOf(`/${pathName}`);
  const currentRoute = routes[currentIndex];
  const nextRoute = routes[currentIndex + 1];
  const prevRoute = routes[currentIndex - 1];
  const hasNext = Boolean(nextRoute);
  const hasPrev = Boolean(prevRoute);
  const hasCompletedStep = completedSteps[currentRoute];

  const setCompletedStep = useCallback(() => {
    setStep((steps) => ({
      ...steps,
      [currentRoute]: true,
    }));

    localStorage.setItem('lastCompletedStep', currentRoute);
  }, [setStep, currentRoute]);

  return {
    hasCompletedStep,
    hasNext,
    hasPrev,
    currentRoute,
    nextRoute: hasNext ? nextRoute : currentRoute,
    prevRoute: hasPrev ? prevRoute : currentRoute,
    setCompletedStep,
  };
};

export const useBounceToRoute = (isSignup, isAuthenticated) => {
  const [route, setRoute] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      if (isSignup) {
        const lastCompletedStep = localStorage.getItem('lastCompletedStep');
        const routeKeys = Object.keys(signupRoutes).filter(item => item !== "default");
        const confirmIndex = routeKeys.indexOf(signupRoutes.confirm.substr(1));

        if (lastCompletedStep) {
          const index = routeKeys.indexOf(lastCompletedStep.substr(1));

          if (index !== confirmIndex) {
            setRoute(`/${routeKeys[index]}`);
          } else {
            localStorage.removeItem('lastCompletedStep');
          }
        } else {
          setRoute(signupRoutes.welcome);
        }
      } else {
        setRoute(appRoutes.insights);
      }
    } else {
      if (isSignup) {
        setRoute(signupRoutes.welcome);
      } else {
        setRoute(appRoutes.login);
      }
    }
  }, [isAuthenticated, isSignup]);

  return route;
};

export const useUpdateField = (userId) => {
  const [update, { data, loading, error }] = useMutation(UPDATE_FIELD_MUTATION);

  const updateField = async (field, value) => {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }

    return await update({
      variables: {
        id: userId,
        field,
        value,
      },
    });
  };

  return {
    updateField,
    data,
    loading,
    error
  };
};

export const useVerifyAndLoginUser = (code, email, password) => {
  const [verify, { data, loading, error }] = useMutation(VERIFY_USER_MUTATION, {
    variables: {
      code: code.trim(),
    },
  });

  const [login, { data: data1, loading: loading1, error: error1 }] = useMutation(
    LOGIN_MUTATION,
    {
      variables: {
        email: email.trim(),
        password,
      },
    }
  );

  const verifyAndLogin = () => {
    return verify()
      .then(login)
      .catch((e) => console.log("verify and login error: ", e));
  };

  return {
    verifyAndLogin,
    data: data || data1 ? { ...data, ...data1 } : undefined,
    loading: [loading, loading1].some(Boolean),
    error: error || error1,
  };
};
