import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { ButtonWrapper } from "../shared/styled";
import { useCurrentUser } from "../../../lib/hooks";
import { useVerifyAndLoginUser, useSignupNavigation } from "../shared/hooks";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { _userState } from "../../../store";

const { verify: { attrs } } = inputFieldOptions

export const CTA = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const { email, password, verified, verificationCode } = useRecoilValue(_userState);
  const { userLoading } = useCurrentUser();
  const { verifyAndLogin, loading, error } = useVerifyAndLoginUser(
    verificationCode,
    email,
    password
  );
  const {
    nextRoute,
    hasCompletedStep,
    setCompletedStep,
  } = useSignupNavigation();

  useEffect(() => {
    setDisabled(
      [loading, userLoading, verificationCode.length < attrs.minLength].some(Boolean)
    );
  }, [loading, userLoading, verificationCode]);

  useEffect(() => {
    if (verified) {
      setCompletedStep();
    }
  }, [verified, setCompletedStep]);

  const onClick = useCallback(() => {
    hasCompletedStep
      ? history.push(nextRoute)
      : verifyAndLogin().then(({ data: { login } }) => {
          if (login) {
            history.push(nextRoute);
            setCompletedStep();
          }
        });
  }, [hasCompletedStep, history, nextRoute, setCompletedStep, verifyAndLogin]);
  
  return (
    <ButtonWrapper>
      {error && <Error error={error} />}
      <Button
        onClick={onClick}
        disabled={hasCompletedStep ? false : disabled}
        loading={loading}
        cta
      >
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : hasCompletedStep ? (
          "Next"
        ) : (
          "Verify its You"
        )}
      </Button>
    </ButtonWrapper>
  );
};
