import React, { useCallback } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import TextInput from "../../TextInput";
import { Container, FormGroup } from "../shared/styled";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { team, _teamState } from "../../../store";

const { teamName: { attrs } } = inputFieldOptions;

export const Form = () => {
  const { name } = useRecoilValue(_teamState);
  const setTeam = useSetRecoilState(team);

  const onChange = useCallback(
    (e, type) => {
      e.preventDefault();
      const { target: { value } } = e;

      setTeam((team) => ({
        ...team,
        [type]: value,
      }));
    },
    [setTeam]
  );

  return (
    <Container>
      <FormGroup>
        <TextInput
          attrs={attrs}
          value={name.charAt(0).toUpperCase() + name.slice(1)}
          onChange={(e) => onChange(e, "name")}
        />
      </FormGroup>
    </Container>
  )
};