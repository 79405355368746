import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.bg};
    margin: 0;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;
    *::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }

  #layout {
    height: 100vh;
    padding: 20px;
  }

  * {
    font-family: Favorit-Regular, Helvetica, sans-serif !important;
    box-sizing: border-box;
  }

  *::selection {
    background: ${({ theme }) => theme.brand_primary}; 
  }

  *::-moz-selection {
    background: ${({ theme }) => theme.brand_primary};
  }
  }`;
