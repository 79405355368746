export const TEAM_QUERY = `
  query companyQuery {
    getTeam {
      id
      name
      dba
      nextDeposit
      isSubscribed
      manager {
        id
        name
        email
        mailing_address {
          address_1
          address_2
          city
          stprv
          country
          postal_code
        }
      }
      plan {
        cost
        name
        description
        credits
        max_rollover
        cost
      }
      userQuota
      redeemed {
        id
        name
        email
        last_login
        created_at
        allSessions {
          stage
          discovery
          seeker_joined_time
          date
          length
        }
      }
      invites {
        email
        invited
      }
      credits
      paymentMethod {
        brand
        last4
      }
      last_report {
        id
        period_start
        period_end
        insights {
          nps
          focus
          happiness_factor
          communication_style
          feelings
          utilization
          numGuides
        }
      }
      reports {
        id
        period_start
        period_end
        sessions {
          id
          discovery
        }
      }
    }
  }
`;

export const TEAM_QUERY_LITE = `
query companyQuery {
  getTeam {
    id
    name
    dba
    t_and_c
    adminTitle
    isSubscribed
    manager {
      id
      name
      email
      mailing_address {
        address_1
        address_2
        city
        stprv
        country
        postal_code
      }
    }
    plan {
      id
      cost
      credits
      description
      interval
      max_rollover
      name
      period
    }
    userQuota
    invites {
      email
      invited
    }
    credits
    paymentMethod {
      brand
      last4
    }
  }
}
`;

export const GET_REPORT_QUERY = `
  query getTeamReport($periodId: ID!) {
    getTeamReport(periodId: $periodId) {
      id
      period_start
      period_end
      insights {
        nps
        focus
        happiness_factor
        communication_style
        feelings
        utilization
        numGuides
      }
    }
  }
`;

export const CURRENT_USER_QUERY = `
  query CurrentUser {
    currentUser {
      id
      country
      email
      first_name
      last_name
      type
      verified
      paymentMethods {
        id
        last4
        exp_month
        exp_year
        brand
      }
    }
  }
`;
