import React, { useCallback } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { SectionHeading } from "../../Typography";
import { _teamState, team } from "../../../store";

export const MAX_RANGE = 100;

const TeamSizeSlider = () => {
  const { size } = useRecoilValue(_teamState);
  const setTeam = useSetRecoilState(team);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setTeam(team => ({
        ...team,
        size: Number(value)
      }));
  }, [setTeam]);

  return (
    <Container column>
      <Container>
        <SectionHeading>Team Size</SectionHeading>
        <SectionHeading>{size === MAX_RANGE ? `${size}+` : size}</SectionHeading>
      </Container>
      <Slider
        type="range"
        min={1}
        max={MAX_RANGE}
        value={size}
        onChange={onChange}
        id="range-slider"
      />
    </Container>
  );
};

export default TeamSizeSlider;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  justify-content: space-between;
  margin: 11px 0;
  width: 100%;
`;

const Slider = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  background: ${({ theme }) => theme.fg};
  height: 2px;
  outline: none;
  width: 100%;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: ${({ theme }) => theme.bg};
    border: 2px solid ${({ theme }) => theme.fg};
    border-radius: 14px;
    cursor: pointer;
    height: 28px;
    width: 28px;
  }

  &::-moz-range-thumb {
    background: ${({ theme }) => theme.bg};
    border: 2px solid ${({ theme }) => theme.fg};
    border-radius: 14px;
    cursor: pointer;
    height: 28px;
    width: 28px;
  }
`;
