import React, { useEffect, useState } from "react";
import { useMutation } from "graphql-hooks";
import styled from "styled-components";
import moment from "moment";
import Button from "./Button";
import MenuDetail from "./MenuDetail";
import MenuHeader from "./MenuHeader";
import MenuControls from "./MenuControls";

import {
  DEL_MEMBER_MUTATION,
  RESEND_INVITE_MUTATION,
} from "../network/mutations";

function Member({ member, setCurrentMember, refetch, currentReport }) {
  const [delTeamMember, { data }] = useMutation(DEL_MEMBER_MUTATION);
  const [resendTeamInvite, { data: inviteData }] = useMutation(
    RESEND_INVITE_MUTATION
  );
  const [loading, setLoading] = useState(false);

  const validSessions =
    member.allSessions && member.allSessions.length
      ? member.allSessions.filter((session) => session.stage === "completed")
      : [];

  const meets = validSessions.filter((session) => session.discovery).length;

  const sessions = validSessions.filter((session) => !session.discovery).length;

  const creditsRedeemed =
    validSessions.reduce(
      (total, session) => total + (session.discovery ? 0 : session.length),
      0
    ) / 10;

  const missedSessions = validSessions.filter(
    (session) => !Boolean(session.seeker_joined_time)
  ).length;

  useEffect(() => {
    if (data && data.delTeamMember) {
      refetch();
      setCurrentMember(null);
    }
  }, [data, refetch, setCurrentMember]);

  useEffect(() => {
    if (inviteData && inviteData.resendTeamInvite) {
      refetch();
      setLoading(false);
    }
  }, [inviteData, refetch, setLoading]);

  return (
    <MemberContainer>
      <MenuHeader onClose={() => setCurrentMember(null)}>
        {member.email}
      </MenuHeader>

      {member.name && <MenuDetail title="Name" value={member.name} />}

      {member.created_at && (
        <MenuDetail
          title="Active Since"
          value={moment(member.created_at).format("LL")}
        />
      )}

      {member.last_login && (
        <MenuDetail
          title="Last Online"
          value={moment(member.last_login).format("LL")}
        />
      )}

      <MenuDetail title="Meets" value={meets} />
      <MenuDetail title="Sessions" value={sessions} />

      <MenuDetail title="Credits Redeemed" value={creditsRedeemed} />

      <MenuDetail title="Missed Sessions" value={missedSessions} />

      {!!member.invited && (
        <MenuDetail
          title="Invited on"
          value={moment(member.invited).format("LL")}
        />
      )}

      <MenuControls>
        {!!member.invited && (
          <BtnContainer loading={Number(loading)}>
            <Button
              style={{ marginBottom: 10 }}
              onClick={() => {
                setLoading(true);
                resendTeamInvite({
                  variables: {
                    email: member.email,
                  },
                });
              }}
              cta={false}
            >
              Resend Invite
            </Button>
          </BtnContainer>
        )}
        <Button
          onClick={() => {
            delTeamMember({
              variables: {
                email: member.email,
                companyId: member.company_id,
              },
            });
          }}
          cta={false}
        >
          Remove User
        </Button>
      </MenuControls>
    </MemberContainer>
  );
}
const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

const BtnContainer = styled.div`
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

export default Member;
