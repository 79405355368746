import { selector } from "recoil";
import { plan, team, user } from "./atoms";

import { isEmailValid } from "../lib/helpers";

const MINUTES_PER_CREDIT = 10;

const TAX_RATE = 0.05;

const CREDIT_BASE_PRICE = 30.0;

const pricesPerCredit = {
  "3": CREDIT_BASE_PRICE,
  "6": 28.5, // -5%
  "12": 27.0, // -10%
};

export const discountsPerTerm = {
  "3": 0.05,
  "6": 0.07,
  "12": 0.1,
};

const getPricePerCredit = (term, isMonthly) => {
  const price = pricesPerCredit[term];
  const discount = price * discountsPerTerm[term];
  return isMonthly ? price : price - discount;
};

export const _costState = selector({
  key: "costState",
  get: ({ get }) => {
    const { length, schedule, term } = get(plan);
    const { size } = get(team);
    const isMonthly = schedule.value === "Monthly";
    const creditPrice = getPricePerCredit(term.value, isMonthly);
    const creditsPerMember = (length.value / MINUTES_PER_CREDIT) * term.value;
    const costBeforeTax = creditsPerMember * creditPrice * size;
    const due = isMonthly ? costBeforeTax / term.value : costBeforeTax;
    const tax = isMonthly
      ? (costBeforeTax * TAX_RATE) / term.value
      : costBeforeTax * TAX_RATE;
    const discountPercentage =
      (1 - pricesPerCredit[term.value] / CREDIT_BASE_PRICE) * 100;
    const discount = isMonthly
      ? discountPercentage.toFixed()
      : (discountPercentage + discountsPerTerm[term.value] * 100).toFixed();

    return {
      discount,
      tax: tax.toFixed(2),
      due: due.toFixed(2),
      totalCredits: creditsPerMember * size,
    };
  },
});

export const _oceanPlanState = selector({
  key: "oceanPlanState",
  get: ({ get }) => {
    const { plan, size } = get(team);
    if (plan) {
      const { cost: cents, credits, interval, period: commitment } = plan;
      const cost = (cents / 100);
      const isMonthly = interval === "month";
      const sessionLength = (credits / size) * MINUTES_PER_CREDIT;
      return {
        cost,
        credits,
        commitment,
        isMonthly,
        sessionLength,
      }
    }
    return {};
  }
});

export const _inviteState = selector({
  key: "inviteState",
  get: ({ get }) => {
    const { invites } = get(team);

    const totalValidEmails = invites.reduce((acc, cur) => {
      const isValid = isEmailValid.test(cur);
      if (isValid) acc += 1;
      return acc;
    }, 0);

    const canInvite = invites[0]
      ? invites.filter(Boolean).map((email) => isEmailValid.test(email))
      : [false];

    return {
      totalValidEmails,
      canInvite: canInvite.every(Boolean),
    };
  },
});

export const _userState = selector({
  key: "userState",
  get: ({ get }) => get(user),
});

export const _teamState = selector({
  key: "teamState",
  get: ({ get }) => get(team),
});
