import React from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import Agreement from "../../components/Signup/Terms/Agreement";

const { titles } = copy;

const Terms = () => {
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.terms} hasPrev={hasPrev} prevRoute={prevRoute} offSet>
      <Agreement />
    </Layout>
  );
};
export default Terms;
