import { useMutation } from "graphql-hooks";

export const useCreateMutationHook = (param, mtn) => {
  const [fn, { data, loading, error }] = useMutation(mtn);

  return {
    [param]: fn,
    data,
    loading, 
    error
  };
};

export const capitalize = (string) => {
  const _string = string.split(" ");
  if (_string.length > 1) {
    return _string
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return _string[0].charAt(0).toUpperCase() + _string[0].slice(1);
};

export const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
