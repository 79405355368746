import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "graphql-hooks";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Error from "../../components/Error";
import SphereTeamsLogo from "../../components/SphereTeamsLogo";
import { Body } from "../../components/Typography";

import { appRoutes } from "../../navigation/routes";
import { LOGIN_MUTATION } from "../../network/mutations";
import { isEmailValid } from "../../lib/helpers";

const _propTypes = {
  loginWithFirebase: PropTypes.func.isRequired,
  setSignup: PropTypes.func.isRequired,
};

function Auth({ loginWithFirebase, setSignup }) {
  const [login, { error, loading }] = useMutation(LOGIN_MUTATION);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [notAllowed, setNotAllowed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setPassword("");
  }, [error]);

  useEffect(() => {
    setDisabled([!isEmailValid.test(email), !password.length].some(Boolean));
  }, [email, password, setDisabled]);

  const onLogin = useCallback(
    (e) => {
      e.preventDefault();
      if (email && password) {
        login({
          variables: {
            email,
            password,
          },
        }).then(({ data }) => {
          const { login } = data;
          if (login) {
            const { type } = login;
            if (type === "Company") {
              const onSuccess = () => {
                setTimeout(() => {
                  history.replace(appRoutes.insights);
                }, 300);
              };
              loginWithFirebase(login.jwt, onSuccess);
            } else {
              setNotAllowed(true);
            }
          }
        }).catch(() => {
          setNotAllowed(true);
        })
      }
    },
    [email, password, login, history, loginWithFirebase, setNotAllowed]
  );

  const onClickSignup = useCallback(() => {
    localStorage.setItem("plan", "m30-c6"); // need plan in localstorage to render signup routes
    setSignup(true);
    window.location.reload();
  }, [setSignup]);

  return (
    <Container>
      <LogoContainer isLoading={loading}>
        <SphereTeamsLogo />
      </LogoContainer>
      <Form isLoading={loading} onSubmit={(e) => onLogin(e)}>
        <TextInput
          attrs={{
            type: "email",
            placeholder: "Email",
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          attrs={{
            type: "password",
            placeholder: "Password",
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {(error || notAllowed) && <Error />}

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button disabled={disabled || loading} cta={true} type="submit">
            Login
          </Button>
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              flexDirection: "row",
              // marginLeft: 10,
              whiteSpace: "nowrap",
            }}
          >
            {/* <Body>or&nbsp;</Body>
            <Body style={linkStyle} onClick={onClickSignup}>
              Sign up
            </Body> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 25,
          }}
        >
          <Body style={{ fontSize: 12 }}>Forgot Password?&nbsp;</Body>
          <Body style={{ ...linkStyle, fontSize: 12 }}>
            <a title="Forgot Password" href={appRoutes.forgotPassword}>Click here.</a>
          </Body>
        </div>
      </Form>
    </Container>
  );
}

Auth.propTypes = _propTypes;

const linkStyle = {
  textDecoration: "underline",
  cursor: "pointer",
};

const Container = styled.div`
  background: black;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  position: relative;
  top: ${({ isLoading }) => (isLoading ? 80 : 0)}px;
  transition: top 0.8s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Form = styled.form`
  width: 300px;
  margin: 20px;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
`;

export default Auth;
