import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { Gradient } from "../../components/Signup/shared/styled";
import { team } from "../../store";
import { SubHeading } from "../../components/Typography";
import { useSignupNavigation, useSendSampleInvite } from "../../components/Signup/shared/hooks";
import { CTA } from "../../components/Signup/Invite/CTA";
import { Form } from "../../components/Signup/Invite/Form";

const { titles, subHeadings } = copy;

const Invite = ({ ...props }) => {
  const [sampleInviteSent, setSampleInviteSent] = useState(false);
  const { invites } = useRecoilValue(team);
  const { sendSampleInvite, loading: sampleLoading } = useSendSampleInvite();
  const { hasPrev, prevRoute } = useSignupNavigation();

  const onSendSampleInvite = useCallback(() => {
    setSampleInviteSent(true);
    sendSampleInvite()
    .finally(() => {
      setTimeout(() => {
        setSampleInviteSent(false);
      }, 2000);
    });
  }, [sendSampleInvite, setSampleInviteSent]);

  return (
    <Layout title={titles.invite} hasPrev={hasPrev} prevRoute={prevRoute} offSet={invites.length >= 10}>
      <SubHeading>
        <span>{subHeadings.invite}</span>
        <br />
        <span>
          {!sampleInviteSent ? (
            // eslint-disable-next-line
            <a title="sphere example invite" onClick={onSendSampleInvite}>
              Receive an example invite
            </a>
          ) : (
            "Example invite sent"
          )}
        </span>
      </SubHeading>

      <Form />
      <CTA sampleLoading={sampleLoading} {...props} />
      {invites.length >= 10 && (
        <Gradient />
      )}
    </Layout>
  );
};

export default Invite;
