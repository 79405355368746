import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import Error from "../../Error";
import TextInput from "../../TextInput";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isEmailValid } from "../../../lib/helpers";
import { forgotPasswordAtom } from "../../../store";

const attrs = {
  placeholder: "Enter your Email",
  type: "email",
};

const Form = () => {
  const setState = useSetRecoilState(forgotPasswordAtom);
  const { email, error } = useRecoilValue(forgotPasswordAtom);

  useEffect(() => {
    const element = document.querySelector(`#forgotpw-input`);

    const listener = () => {
      if (!error && email.length) {
        const isValid = isEmailValid.test(email);
        setState(state => ({
          ...state,
          error: !isValid
        }));
      }
    };

    if (element) {
      element.addEventListener("blur", listener);

      return () => {
        element.removeEventListener("blur", listener);
      };
    }
  }, [email, error, setState]);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setState(state => {
        if (error) {
          return ({
            ...state,
            error: false,
          })
        }
        return ({
          ...state,
          email: value
        })
      })
    },
    [error, setState]
  );

  return (
    <InputContainer>
      <TextInput
        id="forgotpw-input"
        attrs={attrs}
        value={email}
        onChange={onChange}
      />
      {error && <Error style={{ marginBottom: 0 }} error={{ client: { message: "Not a valid email." } }} />}
    </InputContainer>
  )
};

export default Form;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: flex-end;
  margin: 20px 0 15px;
  width: 100%;
`;
