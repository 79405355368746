import React, { useState } from "react";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { CTA } from "../../components/Signup/Corporate/CTA";
import { Form } from "../../components/Signup/Corporate/Form";

const { titles } = copy;

const Corporate = () => {
  const [unavailable, setUnavailable] = useState(false);
  const { hasPrev, prevRoute } = useSignupNavigation();

  return (
    <Layout title={titles.corporate} hasPrev={hasPrev} prevRoute={prevRoute} inline>
      <Form unavailable={unavailable} />
      <CTA unavailable={unavailable} setUnavailable={setUnavailable} />
    </Layout>
  );
};


export default Corporate;
