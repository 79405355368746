import React from "react";
import styled from "styled-components";
import SphereTeamsLogo from "../../SphereTeamsLogo";


export const SignupHeader = () => (
  <Container>
    <SphereTeamsLogo />
  </Container>
);

export default SignupHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  border: none;
  justify-content: space-between;
  align-items: center;
`;
