import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import variables from "../../../variables";
import { Heading, SubHeading } from "../../Typography";

const arrow = require("../../../images/arrow_down.png");

const { breakpoints: { small, medium } } = variables;

const _propTypes = {
  children: PropTypes.any.isRequired,
  hasPrev: PropTypes.bool.isRequired,
  prevRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const Layout = ({ children, hasPrev, prevRoute, title, offSet, inline }) => (
  <Container>
    {hasPrev && (
      <ButtonContainer>
        <Link replace to={prevRoute}>
          <Arrow />
        </Link>
      </ButtonContainer>
    )}
    
    <Content offSet={offSet}>
      <HeadingContainer inline={inline} hasPrev={hasPrev}>
        <Heading>{title}</Heading>
      </HeadingContainer>
      {children}
    </Content>
  </Container>
);

Layout.propTypes = _propTypes;

export default Layout;

const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-x: scroll;
  width: 100%;
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  & > ${Heading}, ${SubHeading} {
    @media (min-width: ${medium}px) {
      white-space: pre;
    }
  }

  @media (min-width: ${small}px) {
    justify-content: ${({ offSet }) => offSet ? 'flex-start' : 'center'};
    width: 400px;
  }
`;

const HeadingContainer = styled.div`
  margin-top: ${({ hasPrev }) => !hasPrev && '30px'};
  
  @media(min-width: ${medium}px) {
    width: ${({ inline }) => inline ? '100%' : '200%'};
    white-space: pre;
  }
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  height: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 30px;
  
  @media (min-width: ${small}px) {
    position: absolute;
    top: 50%;
    left: 20px;
    bottom: 50%;
  }
`;

const Arrow = styled.div`
  background: center / contain no-repeat url(${arrow});
  cursor: pointer;
  height: 100%;
  opacity: 0.5;
  transform: rotate(90deg);
  transition: opacity 150ms ease-in;
  width: 100%;
  &:hover {
    opacity: 1;
  }
`;
