import styled from 'styled-components';
import { FinePrint } from "../../Typography";
import variables from "../../../variables";

const { breakpoints: { medium } } = variables;

export const Container = styled.div`
  background: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const FormGroup = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const FormGrid = styled(FormGroup)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-column-gap: 10px;
`;

export const Error = styled(FinePrint)`
  color: ${({ theme }) => theme.error};
  margin-bottom: 15px;
  padding: 0;
  ${({ style }) => style};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px; // button + error text;
  justify-content: center;
  width: 100%;
`;

export const DetailRow = styled.div`
  border-bottom: ${({ theme, underline }) => underline && `2px solid ${theme.fg}`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ underline }) => underline ? '15px 0 13px 0' : '15px 0'};
`;

export const Gradient = styled.div`
  display: none;

  @media (min-width: ${medium}px) {
    align-self: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 25%,
      rgba(255, 255, 255, 0) 100%
    );
    display: flex;
    height: 200px;
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
  }
`;

export const AnimatedWrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 500ms ease-in;
`;