import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../../Button";
import Error from "../../Error";
import LoadingSpinner from "../../LoadingSpinner";
import { useCurrentUser } from "../../../lib/hooks";
import { useSignupNavigation, useAddPaymentMethod } from "../shared/hooks";
import { ButtonWrapper } from "../shared/styled";
import { _userState } from "../../../store";

export const CTA = () => {
  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const { userId, paymentMethods } = useRecoilValue(_userState);
  const { addPaymentMethod, paymentLoading, paymentError } = useAddPaymentMethod();
  const { nextRoute, setCompletedStep } = useSignupNavigation();
  const { userLoading } = useCurrentUser();

  useEffect(() => setLoading(paymentLoading), [paymentLoading]);

  useEffect(() => {
    if (paymentMethods.length) {
      setDisabled(false);
    }
  }, [paymentMethods, setDisabled]);

  useEffect(() => {
    const element = elements.getElement(CardElement);
    if (element) {
      element.on("change", ({ complete, error }) => {
        setDisabled(!complete);
        setStripeError(Boolean(error));
      });
    }
  }, [elements]);

  const onClick = useCallback(
    async (event) => {
      if (paymentMethods.length) {
        setCompletedStep();
        history.push(nextRoute);
      } else {
        event.preventDefault();
        setLoading(true);
        stripe
          .createToken(elements.getElement(CardElement))
          .then(({ token: { id } }) => {
            addPaymentMethod({
              variables: {
                userId,
                token: id,
              },
            }).then(({ data }) => {
              if (data && data.addPaymentMethod) {
                setCompletedStep();
                setLoading(false);
                history.push(nextRoute);
              } else {
                setLoading(false);
                setStripeError(true);
              }
            });
          })
      }
    },
    [
      addPaymentMethod, 
      elements, 
      history, 
      nextRoute, 
      paymentMethods, 
      setCompletedStep, 
      setLoading, 
      setStripeError,
      stripe, 
      userId
    ]
  );

  return (
    <ButtonWrapper>
      {stripeError && <Error />}
      {paymentError && <Error error={paymentError} />}

      <Button onClick={onClick} disabled={disabled || isLoading || userLoading} cta>
        {isLoading ? <LoadingSpinner loading={isLoading} /> : "Next"}
      </Button>
    </ButtonWrapper>
  )
};
