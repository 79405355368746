import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function TextReveal({ shouldHide, children, delay = 0, html }) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if ((html || children) && !shouldHide) {
      setTimeout(() => setReady(true), delay)
    } else {
      setReady(false);
    }
  }, [html, children, shouldHide, delay]);

  return (
    <Text ready={ready} html={html}>
      {children ?
        <span>{children}</span>
        :
        <span dangerouslySetInnerHTML={{__html: html}} />
      }
    </Text>
  )
}

const Text = styled.div`
  overflow: hidden;
  color: white;
  letter-spacing: -0.5px;
  min-width: 200px;
  text-transform: ${({html}) => !html ? 'capitalize' : 'none'};
  span {
    display: inline-block;
    transition: transform 1.2s cubic-bezier(0.24, 1, 0.32, 1);
    transform: translate3d(0px, ${({ready}) => ready ? 0 : 150}%, 0px) rotate(${({ready}) => ready ? 0 : 3}deg);
  }
`;

export default TextReveal;
