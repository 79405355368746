import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Layout from "../../components/Signup/shared/Layout";
import copy from "../../components/Signup/shared/communications";
import { useSignupNavigation } from "../../components/Signup/shared/hooks";
import { SubHeading } from "../../components/Typography";
import { _costState, plan, _teamState, _userState } from "../../store";
import { CTA } from "../../components/Signup/Confirm/CTA";
import { Form } from "../../components/Signup/Confirm/Form";

const { titles, subHeadings } = copy;

const Confirm = ({ ...props }) => {
  const [details, setDetails] = useState([]);
  const { tax, due } = useRecoilValue(_costState);
  const { length, schedule, term } = useRecoilValue(plan);
  const { size, country } = useRecoilValue(_teamState);
  const { paymentMethods } = useRecoilValue(_userState);
  const { hasPrev, prevRoute } = useSignupNavigation();

  useEffect(() => {
    if (paymentMethods && paymentMethods.length) {
      const isMonthly = schedule.value === `Monthly`;
      const termCostKey = isMonthly ? "Monthly Cost" : "Up-front Cost";
      const taxObj =
        country.value === "CA" ? { Tax: `CAD $${tax}`, underline: true } : null;
      setDetails([
        {
          "Your Team": `${size} Invitee${size > 1 ? `s` : ``}`,
        },
        {
          "Session Availability": `${length.value}min / month each`,
        },
        {
          "Billing Schedule": `${isMonthly ? `Monthly` : `Up-front`}`,
        },
        {
          "Commitment": `${term.value} Months`,
        },
        {
          "Payment Method": `${
            paymentMethods[0].brand[0].toUpperCase() +
            paymentMethods[0].brand.substring(1)
          } ending in ${paymentMethods[0].last4}`,
          underline: true,
        },
        {
          [termCostKey]: `${country.value === `CA` ? `CA` : `US`}D $${due}`,
        },
        taxObj,
        {
          "Pay Today": `${
            country.value === "CA"
              ? `CAD $${(Number(due) + Number(tax)).toFixed(2)}`
              : `USD $${due}`
          }`,
        },
      ]);
    }
  }, [paymentMethods, size, due, tax, length, schedule, country, term]);

  return (
    <Layout title={titles.confirm} hasPrev={hasPrev} prevRoute={prevRoute}>
      <SubHeading>
        <span>{subHeadings.confirm}</span>
      </SubHeading>

      <Form details={details} />
      <CTA {...props} details={details} /> 
    </Layout>
  );
};

export default Confirm;
