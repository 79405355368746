import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextInput from "../../TextInput";
import { Error } from "../shared/styled";
import { isEmailValid } from "../../../lib/helpers";

const EmailField = ({ ...props }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    const element = document.querySelector(`#${props.id}`);

    const listener = () => {
      const isValid = isEmailValid.test(props.value);
      setError(props.value && props.value.length && !isValid);
    };

    element.addEventListener('blur', listener);

    return () => {
      element.removeEventListener('blur', listener);
    }

  }, [props.value, props.id]);
  
  return (
    <InputContainer error={error}>
      <TextInput {...props} />
      {error && <Error>Invalid email</Error>}
    </InputContainer>
  );
};

export default EmailField;

const InputContainer = styled.div`
  height: ${({ error }) => (error ? "60px" : "40px")};
  transition: height 300ms ease-out;
`;
