import React from "react";
import PropTypes from "prop-types";
import variables from "../variables";
import styled, { css } from "styled-components";

const _propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  cta: PropTypes.bool,
  disabled: PropTypes.bool,
  little: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

const ButtonInline = ({
  children,
  little = false,
  disabled = false,
  onClick,
  cta,
  style = {},
}) => (
  <Button
    disabled={disabled}
    little={little}
    onClick={onClick}
    cta={cta}
    styles={css`${style}`}
  >
    {children}
  </Button>
);

ButtonInline.propTypes = _propTypes;

const Button = styled.button`
  align-items: center;
  background-color: ${({ cta }) => cta ? variables.blue_primary : variables.white_primary};
  border-radius: 10px;
  color: ${({ cta }) => cta ? variables.white : variables.black_primary};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  font-size: ${({ little }) => little ? "14px" : "24px"};
  letter-spacing: -0.5px;
  height: ${({ little }) => little ? "30px" : "40px"};
  justify-content: center;
  width: 100%;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => disabled ? "auto" : "pointer"};
  transition: opacity 300ms ease-in-out;
  ${({ styles }) => styles};
`;

export default ButtonInline;
