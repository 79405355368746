export const regions = {
  countryOptions: [
    {}, // allow the placeholder to be the default value
    {
      id: 0,
      label: "Canada",
      value: "CA",
    },
    {
      id: 1,
      label: "United States",
      value: "US",
    },
    {
      id: 2,
      label: "Other",
      value: "OTHER",
    },
  ],
  CA: [
    {},
    {
      id: 0,
      label: "Alberta",
      value: "AB"
    },
    {
      id: 1,
      label: "British Columbia",
      value: "BC"
    },
    {
      id: 2,
      label: "Manitoba",
      value: "MB"
    },
    {
      id: 3,
      label: "New Brunswick",
      value: "NB"
    },
    {
      id: 4,
      label: "Newfoundland and Labrador",
      value: "NL"
    },
    {
      id: 5,
      label: "Northwest Territories",
      value: "NT"
    },
    {
      id: 6,
      label: "Nova Scotia",
      value: "NS"
    },
    {
      id: 7,
      label: "Nunavut",
      value: "NU"
    },
    {
      id: 8,
      label: "Ontario",
      value: "ON"
    },
    {
      id: 9,
      label: "Prince Edward Island",
      value: "PE"
    },
    {
      id: 10,
      label: "Quebec",
      value: "QC"
    },
    {
      id: 11,
      label: "Saskatchewan",
      value: "SK"
    },
    {
      id: 12,
      label: "Yukon Territory",
      value: "YT"
    }
  ],
  US: [
    {},
    {
      id: 0,
      label: "Alabama",
      value: "AL"
    },
    {
      id: 1,
      label: "Alaska",
      value: "AK"
    },
    {
      id: 2,
      label: "American Samoa",
      value: "AS"
    },
    {
      id: 3,
      label: "Arizona",
      value: "AZ"
    },
    {
      id: 4,
      label: "Arkansas",
      value: "AR"
    },
    {
      id: 5,
      label: "California",
      value: "CA"
    },
    {
      id: 6,
      label: "Colorado",
      value: "CO"
    },
    {
      id: 7,
      label: "Connecticut",
      value: "CT"
    },
    {
      id: 8,
      label: "Delaware",
      value: "DE"
    },
    {
      id: 9,
      label: "District Of Columbia",
      value: "DC"
    },
    {
      id: 10,
      label: "Federated States Of Micronesia",
      value: "FM"
    },
    {
      id: 11,
      label: "Florida",
      value: "FL"
    },
    {
      id: 12,
      label: "Georgia",
      value: "GA"
    },
    {
      id: 13,
      label: "Guam",
      value: "GU"
    },
    {
      id: 14,
      label: "Hawaii",
      value: "HI"
    },
    {
      id: 15,
      label: "Idaho",
      value: "ID"
    },
    {
      id: 16,
      label: "Illinois",
      value: "IL"
    },
    {
      id: 17,
      label: "Indiana",
      value: "IN"
    },
    {
      id: 18,
      label: "Iowa",
      value: "IA"
    },
    {
      id: 19,
      label: "Kansas",
      value: "KS"
    },
    {
      id: 20,
      label: "Kentucky",
      value: "KY"
    },
    {
      id: 21,
      label: "Louisiana",
      value: "LA"
    },
    {
      id: 22,
      label: "Maine",
      value: "ME"
    },
    {
      id: 23,
      label: "Marshall Islands",
      value: "MH"
    },
    {
      id: 24,
      label: "Maryland",
      value: "MD"
    },
    {
      id: 25,
      label: "Massachusetts",
      value: "MA"
    },
    {
      id: 26,
      label: "Michigan",
      value: "MI"
    },
    {
      id: 27,
      label: "Minnesota",
      value: "MN"
    },
    {
      id: 28,
      label: "Mississippi",
      value: "MS"
    },
    {
      id: 29,
      label: "Missouri",
      value: "MO"
    },
    {
      id: 30,
      label: "Montana",
      value: "MT"
    },
    {
      id: 31,
      label: "Nebraska",
      value: "NE"
    },
    {
      id: 32,
      label: "Nevada",
      value: "NV"
    },
    {
      id: 33,
      label: "New Hampshire",
      value: "NH"
    },
    {
      id: 34,
      label: "New Jersey",
      value: "NJ"
    },
    {
      id: 35,
      label: "New Mexico",
      value: "NM"
    },
    {
      id: 36,
      label: "New York",
      value: "NY"
    },
    {
      id: 37,
      label: "North Carolina",
      value: "NC"
    },
    {
      id: 38,
      label: "North Dakota",
      value: "ND"
    },
    {
      id: 39,
      label: "Northern Mariana Islands",
      value: "MP"
    },
    {
      id: 40,
      label: "Ohio",
      value: "OH"
    },
    {
      id: 41,
      label: "Oklahoma",
      value: "OK"
    },
    {
      id: 42,
      label: "Oregon",
      value: "OR"
    },
    {
      id: 43,
      label: "Palau",
      value: "PW"
    },
    {
      id: 44,
      label: "Pennsylvania",
      value: "PA"
    },
    {
      id: 45,
      label: "Puerto Rico",
      value: "PR"
    },
    {
      id: 46,
      label: "Rhode Island",
      value: "RI"
    },
    {
      id: 47,
      label: "South Carolina",
      value: "SC"
    },
    {
      id: 48,
      label: "South Dakota",
      value: "SD"
    },
    {
      id: 49,
      label: "Tennessee",
      value: "TN"
    },
    {
      id: 50,
      label: "Texas",
      value: "TX"
    },
    {
      id: 51,
      label: "Utah",
      value: "UT"
    },
    {
      id: 52,
      label: "Vermont",
      value: "VT"
    },
    {
      id: 53,
      label: "Virgin Islands",
      value: "VI"
    },
    {
      id: 54,
      label: "Virginia",
      value: "VA"
    },
    {
      id: 55,
      label: "Washington",
      value: "WA"
    },
    {
      id: 56,
      label: "West Virginia",
      value: "WV"
    },
    {
      id: 57,
      label: "Wisconsin",
      value: "WI"
    },
    {
      id: 58,
      label: "Wyoming",
      value: "WY"
    }
  ],
  "OTHER": [
    {},
    {
      id: 0,
      label: "Other",
      value: "OTHER",
    }
  ]
};

