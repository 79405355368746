import React from 'react';
import styled from 'styled-components';

// Assets
import logo from '../images/sphere-teams-logo.png';

const SphereTeamsLogo = () => <SphereTeams src={logo} alt="Sphere Teams Logo" />

const SphereTeams = styled.img`
  height: 40px;
`;

export default SphereTeamsLogo;