import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Body } from "../../Typography";
import { Container, FormGroup, DetailRow } from "../shared/styled";

const _propTypes = { 
  details: PropTypes.array.isRequired,
};

export const Form = ({ details }) => (
  <Container>
    <FormGroup>
      <TransitionGroup>
        {/* details.taxObj may be null, so we filter */}
        {details.filter(Boolean).map((detail, i) => (
          <CSSTransition key={i} classNames="fade" timeout={300}>
            <DetailRow underline={detail.underline}>
              <Body>{Object.keys(detail)[0]}</Body>
              <Body>{Object.values(detail)}</Body>
            </DetailRow>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </FormGroup>
  </Container>
);
Form.propTypes = _propTypes;
