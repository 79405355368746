export default [
  { id: 0, text: `Tell us about your crew & customize a plan` },
  { id: 1, text: `Activate your plan` },
  { id: 2, text: `Send your team their invitations` },
  { id: 3, text: `Jump into your Insights Portal` },
  { 
    id: 4, 
    text: `Schedule your team's kick-off session`, 
    // href: "https://", 
    // linkText: "kick-off session" 
  }, // TODO: Link to Article which talks about the KICK-OFF SESSION and best practices 
  { id: 5, text: `Saddle-up 🏇🏻 & get growing with coaching` },
  { id: 6, cta: true },
]