import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled, { ThemeProvider } from "styled-components";
import { RecoilRoot } from "recoil";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// import { usePreselectedPlan } from "./components/Signup/shared/hooks";
import { GlobalStyle } from "./theme/GlobalStyle";
import { APP_ROUTES, SIGNUP_ROUTES } from "./navigation/navigationConfig";
import { appRoutes, signupRoutes } from "./navigation/routes";
import SignupHeader from "./components/Signup/shared/SignupHeader";
import withAuth from "./components/withAuth";
import withRouter from "./components/withRouter";
import theme from "./theme";
import "./index.css";

const { login } = appRoutes;
const { welcome } = signupRoutes;

// Root Element
const layout = document.getElementById("layout");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  width: calc(100vw - 40px);

  & > #transition-group {
    height: 100%;
  }
`;

const App = withAuth(
  withRouter(({ isAuthenticated, loading, loginWithFirebase, setToken }) => {
    const location = useLocation();
    // const preselectedPlan = usePreselectedPlan();
    const [isSignup, setSignup] = useState(false);

    useEffect(() => {
      layout.style.height = window.innerHeight + "px";
    }, []);

    // Uncomment this (+ UI @ Auth.js) to allow toggle between signup/login routes
    // useEffect(() => {
    //   setSignup(Boolean(preselectedPlan[0]));
    // }, [preselectedPlan, setSignup]);

    // not ideal, but at the moment i can't find a way to avoid redirecting while firebase resolves
    if (isAuthenticated === null) return null;

    return (
      <RecoilRoot>
        <ThemeProvider theme={theme.dark}>
          <GlobalStyle />
          {isSignup && <SignupHeader />}
          <Container>
            <TransitionGroup id="transition-group">
              <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Switch location={location}>
                  {(isSignup ? SIGNUP_ROUTES : APP_ROUTES).map(
                    ({ route, Component, isProtected }, i) => {
                      if (isProtected) {
                        return (
                          <Route
                            key={location.key + i}
                            path={route}
                            component={(props) => {
                              return isAuthenticated ? (
                                <Component
                                  isAuthenticated={isAuthenticated}
                                  isSignup={isSignup}
                                  loginWithFirebase={loginWithFirebase}
                                  setToken={setToken}
                                  setSignup={setSignup}
                                  loading={loading}
                                  {...props}
                                />
                              ) : (
                                <Redirect
                                  {...props}
                                  to={{
                                    pathname: isSignup ? welcome : login,
                                  }}
                                />
                              );
                            }}
                          />
                        );
                      }

                      return (
                        <Route
                          key={location.key + i}
                          path={route}
                          component={(props) => (
                            <Component
                              isAuthenticated={isAuthenticated}
                              isSignup={isSignup}
                              loginWithFirebase={loginWithFirebase}
                              setToken={setToken}
                              setSignup={setSignup}
                              loading={loading}
                              {...props}
                            />
                          )}
                        />
                      );
                    }
                  )}
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </Container>
        </ThemeProvider>
      </RecoilRoot>
    );
  })
);

ReactDOM.render(<App />, layout);
