import React, { useCallback, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import TextInput from "../../TextInput";
import { useSignupNavigation } from "../shared/hooks";
import { Container, FormGrid } from "../shared/styled";
import { user, _userState, completedSignupSteps } from "../../../store";
import { inputFieldOptions } from "../shared/inputFieldOptions";

export const Form = () => {
  const userState = useRecoilValue(_userState);
  const setUser = useSetRecoilState(user);
  const setStep = useSetRecoilState(completedSignupSteps);
  const { email } = userState;
  const [existingEmail] = useState(email);
  const { currentRoute, hasCompletedStep } = useSignupNavigation();

  // leave completed values as read-only when navigating back to this step,
  // unless email changes in which case we hit p-api again
  const fieldDisabled = hasCompletedStep && existingEmail === email;

  const onChange = useCallback(
    (e, type) => {
      e.preventDefault();
      const { target: { value } } = e;

      if (type === "email") {
        setStep((steps) => ({
          ...steps,
          [currentRoute]: value === existingEmail,
        }));

        if (value === existingEmail) {
          localStorage.setItem('lastCompletedStep', currentRoute);
        } else {
          localStorage.removeItem('lastCompletedStep');
        }
      }

      setUser((user) => ({
        ...user,
        [type]: value.trim(),
      }));
    },
    [currentRoute, existingEmail, setStep, setUser]
  );

  return (
    <Container>
      <FormGrid>
        {inputFieldOptions.getStarted.map(({ id, value, attrs, style }) => (
          <TextInput
            key={id}
            attrs={{ ...attrs, disabled: value !== "email" && fieldDisabled }}
            value={userState[value]}
            onChange={(e) => onChange(e, value)}
            style={style}
          />
        ))}
      </FormGrid>
    </Container>
  );
};