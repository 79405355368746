import React, { useEffect, useState } from "react";
import { useMutation } from "graphql-hooks";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import { useCurrentUser } from "../lib/hooks";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { pickStripe } from "../lib/uris";

import Button from "./Button";
import MenuHeader from "./MenuHeader";
import MenuControls from "./MenuControls";

import { ADD_PM_MUTATION } from "../network/mutations";

const stripeKey = window.Stripe(pickStripe());

function BillingStripeWrapper({ company, refetch, setShowBilling }) {
  return (
    <Elements stripe={stripeKey}>
      <Billing
        company={company}
        refetch={refetch}
        setShowBilling={setShowBilling}
      />
    </Elements>
  );
}

function Billing({ company, refetch, setShowBilling }) {
  const [addPaymentMethod, { data }] = useMutation(ADD_PM_MUTATION);
  const [paymentToken, setPaymentToken] = useState(null);
  const { userLoading } = useCurrentUser();

  useEffect(() => {
    if (data && data.addPaymentMethod) {
      refetch();
    }
  }, [data, refetch]);

  useEffect(() => {
    if (paymentToken) {
      addPaymentMethod({
        variables: {
          userId: company.manager.id,
          token: paymentToken,
        },
      });
      setShowBilling(false);
    }
  }, [paymentToken, company, addPaymentMethod, setShowBilling]);

  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#fff",
        color: "#fff",
        fontFamily: "Favorit-Regular",
        fontSize: "18px",
        fontSmoothing: "antialiased",
      },
    },
  };

  return (
    <Container>
      <MenuHeader onClose={() => setShowBilling(false)}>
        Billing for {company && company.name}
      </MenuHeader>

      <BillingPadding />
      {/* Hideous I know but the card element won't take spacing styles */}

      <CardElement options={CARD_OPTIONS} />

      <MenuControls>
        <Button
          onClick={async (event) => {
            event.preventDefault();
            stripe
              .createToken(elements.getElement(CardElement))
              .then((result) => {
                setPaymentToken(result.token.id);
              });
          }}
          cta={true}
        >
          {userLoading ? <LoadingSpinner loading={userLoading} /> : "Save Card"}
        </Button>
      </MenuControls>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .buttons {
    height: 90px;
  }
`;

const BillingPadding = styled.div`
  height: 10px;
`;

export default BillingStripeWrapper;
