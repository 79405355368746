import React from "react";
import styled from 'styled-components';

// Components
import TextReveal from './TextReveal';

const InsightDetail = ({
  title,
  value,
  ready,
}) => (
  <Container>
    <Title>{title}</Title>
    <Value><TextReveal ready={ready}>{value}</TextReveal></Value>
  </Container>
);

const Container = styled.div``;

const Title = styled.div`
  font-size: 14px;
  color: #999;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

const Value = styled.div`
  font-size: 24px;
  color: white;
  letter-spacing: -0.5px;
  @media(max-width: 800px) {
    font-size: 20px;
  }
`;

export default InsightDetail;
