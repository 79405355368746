import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import listItems from "./listItems";
import { AnimatedWrapper } from "../shared/styled";
import { useInterval } from "../../../lib/hooks";
import { CTA } from "./CTA";
import { LinkItem, ListItem } from "./fragments";

const _propTypes = {
  hasCompletedStep: PropTypes.bool.isRequired,
};

const List = ({ hasCompletedStep, ...props }) => {
  const [visibleItems, setVisibleItems] = useState(new Array(listItems.length).fill(false));

  useInterval(() => {
    const itemIndex = visibleItems.findIndex((item) => !item);
    const items = visibleItems;
    items[itemIndex] = true;
    setVisibleItems([...items]);
  }, 750, visibleItems.every(Boolean));

  return (
    <ListContainer>
      {listItems.map((item) => {
        const isItemVisible = visibleItems[item.id] || hasCompletedStep;

        if (item.cta) {
          return (
            <div key={item.id} style={{ marginTop: 30 }}>
              <AnimatedWrapper isVisible={isItemVisible}>
                <CTA disabled={!isItemVisible} isVisible={isItemVisible} hasCompletedStep={hasCompletedStep} {...props} />
              </AnimatedWrapper>
            </div>
          );
        }

        if (item.href) {
          return (
            <AnimatedWrapper key={item.id} isVisible={isItemVisible}>
              <LinkItem item={item} isCheckmarkVisible={isItemVisible} hasCompletedStep={hasCompletedStep} />
            </AnimatedWrapper>
          );
        }

        return (
          <AnimatedWrapper key={item.id} isVisible={isItemVisible}>
            <ListItem text={item.text} isCheckmarkVisible={isItemVisible} hasCompletedStep={hasCompletedStep} />
          </AnimatedWrapper>
        );
      })}
    </ListContainer>
  );
};

List.propTypes = _propTypes;

export default List;

const ListContainer = styled.div`
  margin: 20px 0;
`;
