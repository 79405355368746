import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForgotPassword } from "../shared/hooks";
import { ButtonWrapper } from "../../Signup/shared/styled";
import { FinePrint } from "../../Typography";
import { forgotPasswordAtom } from "../../../store";
import Button from "../../Button";

const CTA = () => {
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const { email, error } = useRecoilValue(forgotPasswordAtom);
  const { forgotPassword } = useForgotPassword();

  const onClick = useCallback(() => {
    setLoading(true);
    forgotPassword({
      variables: {
        email: email.trim(),
      },
    })
    .finally(() => {
      // ignore errors
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
      }, 1000);
    });
  }, [forgotPassword, email, setLoading, setSuccess]);

  return (
    <ButtonWrapper style={{ justifyContent: "flex-start", height: 100 }}>
      <Button
        disabled={!email.length || error || loading}
        style={{ marginBottom: 20 }}
        onClick={onClick}
        cta
      >
        Send Email
      </Button>
      {success && !loading && (
        <FinePrint>If this email exists in our records, you will receive your password reset link shortly.</FinePrint>
      )}
    </ButtonWrapper>
  );
};

export default CTA;
