import { useSetRecoilState } from "recoil";
import { useEffect, useRef } from "react";
import { useQuery, useManualQuery } from "graphql-hooks";
import { CURRENT_USER_QUERY, TEAM_QUERY_LITE } from "../network/queries";
import { team, user, plan } from "../store";
import { regions } from "../components/Signup/Corporate/geographicRegions";
import { lengthOptions, scheduleOptions, termOptions } from "../components/Signup/PlanSetup/options";

export const useCurrentUser = (skip) => {
  const [getCurrentUser, { data, loading, error }] = useManualQuery(CURRENT_USER_QUERY);
  const setCurrentUser = useSetRecoilState(user);

  useEffect(() => {
    if (!skip) getCurrentUser();
  }, [getCurrentUser, skip]);

  useEffect(() => {
    if (data && data.currentUser) {
      const {
        currentUser: {
          id, first_name, last_name, email, paymentMethods, verified
        }
      } = data;

      setCurrentUser(user => ({
        ...user,
        userId: id,
        firstName: first_name,
        lastName: last_name,
        email,
        paymentMethods,
        verified,
      }));
    }
  }, [data, setCurrentUser]);

  return {
    userData: data,
    userLoading: loading,
    userError: error
  }
};

export const useTeam = () => {
  const { data, loading, error, refetch } = useQuery(TEAM_QUERY_LITE);
  const setTeam = useSetRecoilState(team);
  const setPlan = useSetRecoilState(plan);

  useEffect(() => {
    if (data && data.getTeam) {
      const {
        getTeam: {
          id,
          dba,
          t_and_c,
          adminTitle,
          isSubscribed,
          manager: {
            name: adminName,
            mailing_address
          },
          name,
          plan,
          userQuota,
          invites,
        },
      } = data;

      if (plan) {
        const { credits, interval, period } = plan;
        const isMonthly = interval === "month";
        const sessionLength = (credits / userQuota) * 10;

        setPlan(plan => ({
          ...plan,
          length: lengthOptions[lengthOptions.findIndex(item => item.value === sessionLength)],
          schedule: scheduleOptions[isMonthly ? 0 : 1],
          term: termOptions[termOptions.findIndex(item => item.value === period)]
        }))
      }

      setTeam((team) => ({
        ...team,
        id,
        name,
        plan,
        adminName,
        isSubscribed,
        acceptedTerms: t_and_c,
        adminTitle: adminTitle ? adminTitle : "",
        invites: invites && invites.length ? invites.map(item => item.email) : [""],
        size: userQuota || team.size,
        corporateName: dba || "",
        corporateAddress1: mailing_address ? mailing_address.address_1 : "",
        corporateAddress2: mailing_address ? mailing_address.address_2 : "",
        city: mailing_address ? mailing_address.city : "",
        country: mailing_address && mailing_address.country
          ? regions.countryOptions.find(
              (item) => item.value === mailing_address.country
            )
          : {},
        province: mailing_address && mailing_address.stprv
          ? regions[mailing_address.country].find(
              (item) => item.label === mailing_address.stprv
            )
          : {},
        postalCode: mailing_address ? mailing_address.postal_code : "",
      }));
    }
  }, [data, setPlan, setTeam]);

  return {
    teamData: data,
    teamLoading: loading,
    teamError: error,
    refetchTeam: refetch,
  };
};

// apparently you can't reliably use setInterval inside react hooks, so here's this.
// with help from jesus aka Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback, delay, clear) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const interval = () => savedCallback.current();

    let id = setInterval(interval, delay);
    if (clear) clearInterval(id);
    return () => clearInterval(id);
  }, [delay, clear]);
};
