import React from "react";
import variables from "../variables";
import styled from "styled-components";

const MenuDetail = ({ title, value }) => (
  <Row>
    <span>{title}</span>
    <span>{value}</span>
  </Row>
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${variables.white_primary};
  font-size: 16px;
  height: 40px;
  align-items: center;
`;

export default MenuDetail;
