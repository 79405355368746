import React, { useEffect, useState } from "react";
import { useMutation } from "graphql-hooks";
import styled from "styled-components";

//Components
import Button from "./Button";
import TextInput from "../components/TextInput";
import ListItem from "../components/ListItem";
import MenuHeader from "./MenuHeader";
import MenuControls from "./MenuControls";

import { ADD_MEMBER_MUTATION } from "../network/mutations";

const Team = ({
  company,
  setCurrentMember,
  currentMember,
  refetch,
  setShowTeam,
}) => {
  const [addTeamMember, { data, error }] = useMutation(ADD_MEMBER_MUTATION);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (data && data.addTeamMember) {
      setEmail("");
      refetch();
      setLoading(false);
    }
  }, [data, refetch, setLoading, setEmail]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
    setEmail("");
  }, [error]);

  return (
    <TeamContainer>
      <MenuHeader
        onClose={() => {
          setShowTeam(false);
          setCurrentMember(null);
        }}
      >
        Team Members
      </MenuHeader>
      <MembersContainer>
        {company &&
          company.redeemed &&
          company.invites &&
          [...company.redeemed, ...company.invites].map((member, index) => (
            <ListItem
              key={index}
              member={member}
              title={member.name || "Inactive User"}
              detail={member.email}
              isOpen={currentMember && member.email === currentMember.email}
              onClick={() => {
                member.company_id = company.id;
                setCurrentMember((prev) => (prev === member ? null : member));
              }}
            />
          ))}
      </MembersContainer>
      <MenuControls>
        {error ? (
          <MenuHeader error>
            There seems to be an error with your account. Please contact support
          </MenuHeader>
        ) : (
          <MenuHeader>Invite Team Members</MenuHeader>
        )}

        <Form
          loading={loading ? 1 : 0}
          onSubmit={(event) => {
            event.preventDefault();
            setLoading(true);
            if (email) {
              addTeamMember({
                variables: {
                  email,
                  companyId: company && company.id,
                },
              });
            }
          }}
        >
          <TextInput
            attrs={{
              type: "email",
              placeholder: "Email",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <Button
            cta={true}
            disabled={
              error
                ? true
                : company &&
                  company.redeemed &&
                  company.invites &&
                  !(
                    company.userQuota -
                    company.invites.length -
                    company.redeemed.length
                  )
            }
            type="submit"
          >
            Add Team Member
          </Button>
        </Form>
      </MenuControls>
    </TeamContainer>
  );
};

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const MembersContainer = styled.div`
  margin: 0 0 20px;
`;

/*
const FormInvitesContainer = styled.div`
  margin-bottom: 0px;
`;
*/

const Form = styled.form`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
`;

export default Team;
