import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Error from "../../Error";
import TextInput from "../../TextInput";
import { Body } from "../../Typography";
import { capitalize } from "../../../lib/helpers";

const _propTypes = {
  acceptedTerms: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  nameToValidate: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const Form = ({ acceptedTerms, nameToValidate, name, setName, title, setTitle }) => {
  const [mismatch, setMismatch] = useState(false);

  useEffect(() => {
    const element = document.querySelector(`#tos-name-input`);
    const listener = () => {
      const isValid = name.toLowerCase().trim() === nameToValidate;
      setMismatch(!isValid);
    };
    if (element) {
      element.addEventListener('blur', listener);

      return () => {
        element.removeEventListener('blur', listener);
      }
    }
  }, [name, nameToValidate]);

  return (
    <CenterContent>
      <Body style={{ marginBottom: 20 }}>{acceptedTerms ? "Signed by:" : "Your name:"}</Body>
      <TextInput
        attrs={{ placeholder: capitalize(nameToValidate) }}
        disabled={acceptedTerms}
        id="tos-name-input"
        onChange={({ target: { value } }) => setName(capitalize(value))}
        value={name}
      />
      {mismatch && <Error error={{ client: { message: "Name does not match" } } } />}

      <Body style={{ marginBottom: 20 }}>{`${!acceptedTerms ? `Your ` : ``}Title:`}</Body>
      <TextInput 
        attrs={{ placeholder: "Title" }}
        disabled={acceptedTerms}
        onChange={({ target: { value } }) => setTitle(capitalize(value))}
        value={title}
      />
    </CenterContent>
  )
};

Form.propTypes = _propTypes;

export const CenterContent = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 75%;
`;

