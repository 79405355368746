import { atom } from "recoil";
import { signupRoutes } from "../navigation/routes";
import { regions } from "../components/Signup/Corporate/geographicRegions";

export const user = atom({
  key: "user",
  default: {
    userId: null,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    paymentMethods: [],
    verified: false,
    verificationCode: '',
  },
});

export const team = atom({
  key: "team",
  default: {
    id: null,
    name: "",
    acceptedTerms: null,
    adminName: "",
    corporateName: "",
    corporateAddress1: "",
    corporateAddress2: "",
    city: "",
    postalCode: "",
    province: regions.CA[0],
    country: regions.countryOptions[0],
    size: 8,
    plan: {},
    invites: [""],
    isSubscribed: false,
  },
});

export const plan = atom({
  key: "plan",
  default: {
    schedule: { id: 0, value: "Monthly" },
    length: { id: 0, value: 30 }, // minutes
    term: { id: 1, value: 6 }, // months
  },
});

export const completedSignupSteps = atom({
  key: "completedSignupSteps",
  default: Array.from(Object.keys(signupRoutes)).reduce((acc, curr) => {
    acc[`/${curr.toLowerCase()}`] = false;
    return acc;
  }, {}),
});

export const forgotPasswordAtom = atom({
  key: "forgotPasswordAtom",
  default: {
    email: "",
    error: false
  }
});
