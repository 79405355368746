import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import TextInput from "../../TextInput";
import { Container, FormGroup } from "../shared/styled";
import { useSignupNavigation } from "../shared/hooks";
import { inputFieldOptions } from "../shared/inputFieldOptions";
import { user } from "../../../store";

const { verify: { attrs } } = inputFieldOptions

export const Form = () => {
  const [code, setCode] = useState('');
  const { hasCompletedStep } = useSignupNavigation();
  const setUser = useSetRecoilState(user);

  useEffect(() => {
    if (code.length === attrs.minLength) {
      setUser(user => ({
        ...user,
        verificationCode: code
      }))
    }

    return () => {
      setUser(user => ({
        ...user,
        verificationCode: ''
      }));
    }
  }, [code, setUser]);

  const onChange = useCallback(
    (evt) => {
      evt.preventDefault();
      const { target: { value } } = evt;

      if (value.length <= attrs.minLength) {
        setCode(value.toUpperCase().trim())
      }
    },
    [setCode]
  );

  return (
    <Container>
      <FormGroup>
        <TextInput
          attrs={{
            ...attrs,
            disabled: hasCompletedStep,
            placeholder: hasCompletedStep ? "Verified" : attrs.placeholder,
          }}
          value={code}
          onChange={(e) => onChange(e)}
        />
      </FormGroup>
    </Container>
  );
};
