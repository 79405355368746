import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";
import EmailField from "./EmailField";
import variables from "../../../variables";
import { Body } from "../../Typography";
import { FormGroup, DetailRow } from "../shared/styled";
import { team, _inviteState, _teamState } from "../../../store";
import { inputFieldOptions } from "../shared/inputFieldOptions";

const { emailField: { attrs } } = inputFieldOptions;

const EmailFields = () => {
  const setTeam = useSetRecoilState(team);
  const { invites, name, size } = useRecoilValue(_teamState);
  const { totalValidEmails, canInvite } = useRecoilValue(_inviteState);
  const [isDisabled, setDisabled] = useState(true);
  const [inputFields, setInputFields] = useState([EmailField]);

  useEffect(() => {  
    setDisabled([
      !canInvite,
      inputFields.length === size,
      inputFields.length > invites.length
    ].some(Boolean));
  }, [canInvite, inputFields.length, invites.length, size]);

  useEffect(() => {
    setInputFields(invites.map(() => EmailField));
  }, [invites, setInputFields]);

  const onClickAdd = useCallback(() => {
    if (!isDisabled) {
      setInputFields([...inputFields, EmailField]);
    }
  }, [isDisabled, inputFields]);

  const onChange = useCallback(
    (event, index) => {
      const { target: { value } } = event;
      const values = [...invites];
      values[index] = value;

      setTeam((team) => ({
        ...team,
        invites: values,
      }));
    },
    [invites, setTeam]
  );

  return (
    <FormGroup>
      <TransitionGroup>
        {inputFields.map((Component, i) => (
          <CSSTransition key={i} classNames="fade" timeout={300}>
            <Component
              id={`invite-textfield-${i}`}
              attrs={{
                ...attrs,
                placeholder: attrs.placeholder(name.toLowerCase()),
              }}
              onChange={(evt) => onChange(evt, i)}
              value={invites[i]}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>

      <DetailRow>
        <Body>{`${totalValidEmails}/${size}`}</Body>
        <PlusContainer onClick={onClickAdd} disabled={isDisabled}>
          <Plus>+</Plus>
          <Body>Add Another</Body>
        </PlusContainer>
      </DetailRow>
    </FormGroup>
  );
};

export default EmailFields;

const PlusContainer = styled.div`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: flex;
  flex-direction: row;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: opacity 300ms ease-out;
`;

const Plus = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.fg};
  border-radius: 7.5px;
  color: ${variables.grey_tertiary};
  height: 15px;
  justify-content: center;
  margin-right: 5px;
  text-align: center;
  width: 15px;
`;
